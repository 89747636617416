import React, { useEffect } from "react";
import "./styles.scss";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { getInsight } from "../../../utils";

const Index = (props) => {
  return (
    <>
      {/* Shimmer start */}
      <>
        <div className={`bsc_main-wrapper-shimmer d-flex flex-column `}>
          <div className={"bsc_mws-bank_logo shimmerBG"}></div>

          <div className={"bsc_mws-bank_Name shimmerBG"}></div>
        </div>
      </>
    </>
  );
};
export default withRouter(Index);
