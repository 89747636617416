import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  BankFavoriteCard,
  BankName,
  BankOfferLink,
  BankTotalCard,
  FilterColor_3,
  LogoRowCard,
} from "../..";
import { GetIcon } from "../../../config/icon";
import { RiErrorWarningLine, RiHeartLine } from "react-icons/ri";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  getBankById,
  getBankImpressionCount,
  getOffersByBank,
} from "../../../service";

const Index = (props) => {
  const [merchantList, setMerchantList] = useState([]);
  const [bankData, setBankData] = useState(null);
  const [offerCount, setOfferCount] = useState(0);
  const [impressionCount, setImpressionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    let resultBank = await getOffersByBank(props.bankId);
    let resultBankImpression = await getBankImpressionCount(props.bankId);
    console.log("getOffersByBank", resultBank);
    console.log("getBankImpressionCount", resultBankImpression);
    setIsLoading(false);
    let bankMerchantList = resultBank?.data?.data;
    let totalBankOfferCount = resultBank?.data?.totalCount;
    let bankImpressionCount = resultBankImpression?.data?.total_impressions;
    setMerchantList(bankMerchantList);
    setOfferCount(totalBankOfferCount);
    setImpressionCount(bankImpressionCount);
  };

  const fetchBankData = async () => {
    if (props.bankId) {
      let resultBank = await getBankById(props.bankId);
      console.log("getBankById", resultBank);
      setBankData(resultBank?.data);
    }
  };

  useEffect(() => {
    if (props.topOffersList) {
      console.log("offersss:::", props.topOffersList);
    }
  }, [props.topOffersList]);

  useEffect(() => {
    if (props.bankId !== "") {
      fetchData();
      fetchBankData();
    }
  }, [props.bankId]);

  return (
    <>
      {!isLoading && (
        <div className="bankInfo_card_wrapper d-flex flex-column">
          <div className="d-flex flex-column align-items-center">
            <div className="bcw_logo">
              <img src={bankData?.logoURL} alt={"logo"} className="bcw_logo" />
            </div>
            <div className="bank_name_wrapper d-flex flex-column">
              <div className="bnw_title">{bankData?.bankName}</div>
              <div className="bnw_content">
                Explore the Best Discounts Across{" "}
                <span className="bnw__c-count">
                  {bankData?.cardTypes?.length}
                </span>{" "}
                credit Card Types
              </div>
            </div>
          </div>
          {/* <BankFavoriteCard
            icon={<RiHeartLine />}
            detail={"Save to Favorites"}
            count={props.favCount}
            isCount={true}
          /> */}

          <div>
            <BankTotalCard
              count={offerCount}
              title={"Total Offers"}
              total={impressionCount}
              name={"Impressions"}
              icon={<RiErrorWarningLine />}
            />
          </div>
          <div>
            <div className="bcw_merchant-logo mb-2">
              {merchantList &&
                merchantList?.length > 0 &&
                (merchantList || []).slice(0, 4).map((item, index) => (
                  <div style={{ marginLeft: "-10px" }}>
                    <LogoRowCard logo={item && item?.logoURL} />
                  </div>
                ))}
              {merchantList?.length > 4 && (
                <div className="bcw_more-count">
                  +{merchantList?.length - 4}
                </div>
              )}
            </div>

            {props.linkContent && (
              <BankOfferLink
                linkContent={props.linkContent}
                onClickLink={() => {
                  props.history.push(
                    `/credit-card-offers/${bankData?.bankName}/${props.bankId}/${props.categoryName}/${props.categoryId}`
                  );
                }}
              />
            )}
          </div>

          <div className="bcw_filter_color_wrapper">
            <FilterColor_3 />
          </div>
        </div>
      )}

      {/* ==== Shimmer start ===== */}
      {isLoading && (
        <>
          <div className={"bankInfo_card_shimmer d-flex flex-column"}>
            <div className={"d-flex flex-column align-items-center mb-3"}>
              <div className={"bcs_logo-shimmer shimmerBG"}></div>
              <div className={"bcs__bank_name_shimmer d-flex flex row"}>
                <div className={"bcs__bns_title-shimmer shimmerBG"}></div>
                <div
                  className={"bcs__bns_content-shimmer shimmerBG"}
                  style={{ marginBottom: "2px" }}
                ></div>
                <div className={"bcs__bns_content-shimmer shimmerBG"}></div>
              </div>
            </div>
            <div className={"d-flex flex-row justify-content-between mb-3"}>
              <div className={"bcs__favorites-shimmer shimmerBG"}></div>
              <div className={" bcs__count-shimmer shimmerBG"}></div>
            </div>

            <div className={"bcs__total-shimmer shimmerBG mb-4"}></div>

            <div
              className={
                "d-flex flex-row justify-content-center align-items-center mb-3"
              }
            >
              <div className={"bcs__small-logo-shimmer shimmerBG"}></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
            </div>

            <div className={"bcs__link-shimmer shimmerBG"}></div>
          </div>
        </>
      )}
      {/* ==== Shimmer end ===== */}
    </>
  );
};
export default withRouter(Index);
