import React from "react";
import "./styles.scss";
//import "./mobile.scss";
import {
  OfferCardFooterItemSmall,
  OfferInfoCardSmall,
  OfferTypeTag,
  ViewsAndFavoriteCardSmall,
} from "../../../components";
import { RiCalendar2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const getLocationName = (location) => {
  for (let i = 0; i < location?.length; i++) {
    if (location[i].isActive) {
      return location[i].value;
    }
  }
};

const Index = (props) => {
  return (
    <>
      <div className={`offercard-small-shimmer d-flex flex-column`}>
        <div className={"d-flex flex-row align-items-center"}>
          <div className={"oss__logo-shimmer shimmerBG"}></div>
          <div className={"d-flex-flex-column"}>
            <div className={"oss__title-shimmer shimmerBG"}></div>
            <div className={"oss__header-shimmer shimmerBG"}></div>
          </div>
        </div>

        <div className={"oss__cs-banner-shimmer shimmerBG"}></div>
        <div className={"offercard-small-footer-shimmer d-flex flex-column"}>
          <div className={"d-flex flex-row"}>
            <div className={"oss__osfs-bank-shimmer shimmerBG"}></div>
            <div className={"oss__osfs-favorite-shimmer shimmerBG"}></div>
          </div>
          <div className={"oss__osfs-valid-shimmer shimmerBG"}></div>
        </div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default withRouter(Index);
