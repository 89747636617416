import React from "react";
import "./styles.scss";
import "./mobile.scss";
import {
  OfferCardFooterItem,
  OfferInfoCard,
  OfferTypeTag,
  Tag,
  ViewsAndFavoriteCard,
} from "../../../components";

import {
  RiCalendar2Line,
  RiEarthLine,
  RiHeadphoneLine,
  RiMapPinLine,
} from "react-icons/ri";
import { Popconfirm } from "antd";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const getLocationName = (location) => {
  for (let i = 0; i < location?.length; i++) {
    if (location[i].isActive) {
      return location[i].value;
    }
  }
};

const Index = (props) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  return (
    <>
      <div
        id={props.id}
        key={props.id}
        ref={props.ref}
        className={`offer-view-card-wrapper flex-column animate__animated ${props.animate}`}
      >
        {props.data?.status === "expireSoon" && (
          <div className={"ending-soon-tag"}>
            <OfferTypeTag text={"Ending soon"} type={"ending-soon"} />
          </div>
        )}
        {props.data?.status === "new" && (
          <div className={"ending-soon-tag"}>
            <OfferTypeTag text={"New"} type={"new"} />
          </div>
        )}
        {/*merchant-header*/}
        <>
          <div
            className={"merchant-header d-flex"}
            style={{ cursor: "pointer" }}
            onClick={props.onClick}
          >
            <div className={"merchant-logo-wrp"}>
              {!props.data.merchantData && props.data.merchant && (
                <img
                  src={
                    props.data &&
                    props.data.merchant &&
                    props.data.merchant?.logoURL
                  }
                  alt="creditcard offers"
                />
              )}
              {!props.data.merchant && props.data.merchantData && (
                <img
                  src={
                    props.data &&
                    props.data.merchantData &&
                    props.data.merchantData?.logoURL
                  }
                  alt="creditcard offers"
                />
              )}
            </div>
            <div className={"merchant-header-wra d-flex flex-column"}>
              <div className={"mh-title"}>{props.data.label}</div>
              <div className={"mh-header"}>
                {props?.data?.header}
              </div>
            </div>
          </div>

          <div
            className={"offer-content-wrapper d-flex"}
            style={{ cursor: "pointer" }}
            onClick={props.onClick}
          >
            <div className={"offer-banner"}>
              <div className={"mobile-location-tag"}>
                {getLocationName(props?.data?.location)}
              </div>
              <img
                className={"offer-banner"}
                loading="lazy"
                src={props.data?.bannerImageURL}
                alt="credit card offers"
              />

              <div className={"offer-tag"}>
                {props.data.offerType === "off" && (
                  <>{props.data?.offerRate}% off</>
                )}
                {props.data.offerType === "upto" && (
                  <>upto {props.data?.offerRate}% off</>
                )}
                {props.data.offerType === "customize" && (
                  <>{props.data?.offerRate}</>
                )}
                {props.data.offerType === "discount" && (
                  <>{props.data?.offerRate}% discount</>
                )}
              </div>
            </div>

            <div className={"offer-info-wrapper"}>
              <OfferInfoCard
                style={"offer-valid"}
                type={"offerValid"}
                lable={"Offer valid"}
                title={null}
                offerStart={props.data.offerStart}
                offerEnd={props.data.offerEnd}
                offerDateType={props.data.offerDateType}
                icon={<RiCalendar2Line />}
              />

              <OfferInfoCard
                style={"location"}
                type={"tag"}
                lable={"location"}
                data={props?.data?.location}
                icon={<RiMapPinLine />}
              />

              {props.data &&
                props.data.merchant &&
                props.data.merchant.contactNo && (
                  <OfferInfoCard
                    style={"reservation-no"}
                    type={"text"}
                    lable={"Reservation No"}
                    title={props.data?.merchant?.contactNo}
                    icon={<RiHeadphoneLine />}
                  />
                )}

              {props.data &&
                props.data.merchant &&
                props.data.merchant.webURL && (
                  <OfferInfoCard
                    style={"web-url"}
                    type={"text"}
                    lable={"Web"}
                    title={
                      props.data &&
                      props.data.merchant &&
                      props.data.merchant.webURL
                    }
                    icon={<RiEarthLine />}
                  />
                )}

              {/*<OfferInfoCard*/}
              {/*    type={"text"}*/}
              {/*    lable={"Valid location"}*/}
              {/*    title={getLocationName(props.data.location)}*/}
              {/*    icon={<RiMapPinLine/>}*/}
              {/*/>*/}

              {/*<OfferInfoCard*/}
              {/*    type={'dateAgo'}*/}
              {/*    lable={null}*/}
              {/*    title={new Date (props.data.createdAt)}*/}
              {/*    icon={<RiTimeLine/>}/>*/}
            </div>
          </div>
        </>

        {/******* offer card footer wrapper ***********/}
        <div
          className={
            "offer-card-footer-wrapper mt-2  d-flex flex-row flex-wrap"
          }
        >
          <OfferCardFooterItem
            style={"bank"}
            title={"Bank"}
            img={
              <img
                className={"lading-bg"}
                loading="lazy"
                src={
                  props.bankData &&
                  props.bankData[0] &&
                  props.bankData[0].thumbnailURL
                }
                alt="all offers in one place"
              />
            }
            content={props.bankData[0] && props.bankData[0].bankName}
            type={"bank"}
          />
          <OfferCardFooterItem
            style={"category"}
            title={"Category"}
            iconName={props.categoryData[0]?.iconName}
            content={
              props.categoryData &&
              props.categoryData[0] &&
              props.categoryData[0]?.categoryName
            }
            icon={props.categoryData[0]?.icon}
            type={"icon"}
          />
          <OfferCardFooterItem
            style={"views"}
            title={"Views"}
            content={props.data?.views}
            iconName={"eye"}
            type={"icon"}
          />
          {isLogin && (
            <OfferCardFooterItem
              style={"favorite"}
              title={"Favorite"}
              iconName={"favorite"}
              isFavorites={props?.isFavorites}
              isLoading={props.isLoading}
              content={props.data?.interest}
              type={"icon"}
              onClick={props.onClickFavorite}
            />
          )}
          {!isLogin && (
            <Popconfirm
              title="Your are not logged in."
              description="Please sign-in to do this."
              placement="topRight"
              okText="SignIn"
              cancelText="SignUp"
              onConfirm={() => props.history.push("/sign-in")}
              onCancel={() => props.history.push("/sign-up")}
            >
              <OfferCardFooterItem
                style={"favorite"}
                title={"Favorite"}
                content={props.data?.interest}
                iconName={"favorite"}
                isFavorites={props?.isFavorites}
                type={"icon"}
                onClick={() => {}}
              />
            </Popconfirm>
          )}
        </div>
        <div className={"offer-card-mobile-footer-wrapper"}>
          <div className={"d-flex flex-row"}>
            <OfferCardFooterItem
              style={"bank"}
              title={"Bank"}
              img={
                <img
                  className={"lading-bg"}
                  src={props.bankData[0]?.thumbnailURL}
                  alt="all offers in one place"
                />
              }
              content={props.bankData[0]?.bankName}
              type={"bank"}
            />
            <div className={"views-and-favorite"}>
              <ViewsAndFavoriteCard
                views={props.data?.views}
                favorites={props.data?.interest}
              />
            </div>
          </div>
          <OfferInfoCard
            style={"offer-valid"}
            type={"offerValid"}
            lable={"Offer valid"}
            title={null}
            offerStart={props.data.offerStart}
            offerEnd={props.data.offerEnd}
            offerDateType={props.data.offerDateType}
            icon={<RiCalendar2Line />}
          />
        </div>
        <div className={"bg-wrapper-1"} style={{ cursor: "pointer" }}>
          <img
            className={"offer-image"}
            src={require("../../../assets/img/offerBg1.svg")}
            alt="credit card offers"
          />
        </div>
        <div className={"bg-wrapper-2"} style={{ cursor: "pointer" }}>
          <img
            className={"offer-image"}
            src={require("../../../assets/img/offerBg2.svg")}
            alt="credit card offers"
          />
        </div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default withRouter(Index);
