import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { Button, ButtonIcon, Input, JoinFormHeader } from "../../../components";
import { GetIcon } from "../../../config/icon";
import * as actionTypes from "../../../store/actions/types";
import {
  getMaskedPhoneNumber,
  GetMessageText,
  getToken,
  validateNumber,
} from "../../../utils";
import { RiArrowRightLine } from "react-icons/ri";
import * as user from "../../../service/userApi";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { openNotification } from "../../../config/notification";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      isFoundUser: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      termsAndCondition: false,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      existingUser: null,
      dataForm: {
        mobileNo: {
          key: "mobileNo",
          elementType: "phoneNo",
          elementConfig: {
            type: "input",
            placeholder: "Ex : 715 986 461",
            minLength: "9",
            maxLength: "9",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 9,
            mobileNo: true,
          },
          valid: false,
          touched: false,
          label: "New Mobile No",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    //========= VALIDATION USER -- OFFER VIEWER
    //this.setState({isLoading: true});
    for (let itemKey of Object.keys(df)) {
      if (itemKey === "mobileNo") {
        if (!df[itemKey].value) {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
            formIsValid = false;
          }
        }
        this.setState({
          dataForm: df,
          formIsValid: formIsValid,
          isLoading: false,
        });
      }
    }
  }

  render() {
    const { dataForm, viewPointHeight, isLoading, isFoundUser } = this.state;
    const { isUpdateMobileNo } = this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isUpdateMobileNo ? "show-me" : "hide-me"
        }`}
      >
        <div
          className={
            "coo_modal-body-content d-flex flex-column fix-536 animate__animated animate__fadeInUp"
          }
          style={{
            top: "0",
            borderRadius: "24px",
            height: "auto",
            padding: "42px",
          }}
        >
          <div className={"coo__mbc-close flex-row"}>
            <ButtonIcon
              type={"close"}
              onClick={() => this.props.onClickCloseUpdateMobileNo()}
              size={"sm-x"}
              buttonIcon={GetIcon("close")}
            />
          </div>
          <JoinFormHeader
            isLogo={true}
            wizardNo={4}
            history={this.props.history}
            header={"Update Mobile Number"}
            subtitle={true}
          />
          <div
            className={
              "coo_modal_body d-flex flex-column justify-content-center align-items-center align-content-center"
            }
          >
            <div style={{ width: "100%" }}>
              <>
                <Input
                  elementConfig={dataForm.mobileNo.elementConfig}
                  elementType={dataForm.mobileNo.elementType}
                  required={dataForm.mobileNo.validation.required}
                  maxLength={dataForm.mobileNo.validation.maxLength}
                  invalidReason={dataForm.mobileNo.invalidReason}
                  isinvalid={!dataForm.mobileNo.valid}
                  touched={dataForm.mobileNo.touched}
                  value={dataForm.mobileNo.value}
                  size={"lg"}
                  verificationStatus={dataForm.mobileNo.valid}
                  showVerification={!!dataForm.mobileNo.value}
                  margin={"m-t-24"}
                  label={dataForm.mobileNo.label}
                  changed={(event) =>
                    this.inputHandleChange(event, dataForm.mobileNo.key)
                  }
                />
              </>
              {isFoundUser && (
                <div className={"mobile-no-found"}>
                  {getMaskedPhoneNumber(dataForm.mobileNo.value)} This mobile
                  number is not registered. Would you like to{" "}
                  <a
                    className={"change-no"}
                    onClick={() => {
                      this.props.setUserMobileNo(dataForm["mobileNo"].value);
                      this.props.history.push("/sign-up");
                      this.props.onClickCloseUpdateMobileNo();
                    }}
                  >
                    Sign up
                  </a>{" "}
                  with it?
                </div>
              )}
            </div>
          </div>

          <div className={"join-form-cta-section"}>
            <Button
              text={"Send Verification Code"}
              type={"dark md"}
              size={"full-width"}
              isLoading={isLoading}
              rightIcon={true}
              rightIconType={"dark"}
              reactIcon={<RiArrowRightLine />}
              onClick={async () => {
                this.setState({ isLoading: true });
                if (dataForm["mobileNo"].value?.length === 9) {
                  const existingMobileRes = await user.isExistingUserMobileNo(
                    dataForm["mobileNo"].value
                  );
                  console.log({ existingMobileRes });
                  if (existingMobileRes?.status) {
                    let token = getToken();
                    console.log("loginUserData>>", this.props.loginUserData);
                    let res = await user.newOTPVerify(
                      this.props.loginUserData?.userId,
                      "MOBILE",
                      token,
                      dataForm["mobileNo"].value
                    );
                    console.log("newOTPVerify>>", res);
                    // this.setState({ isLoading: false });
                    if (res && res.data && res.data.status) {
                      this.props.history.push("/forget-password");
                      this.props.openSignupOTP({
                        user: this.props.loginUserData,
                        token: token,
                      });
                      this.setState({ isLoading: false });
                      this.props.onClickCloseUpdateMobileNo();
                    }
                  } else {
                    this.setState({
                      isFoundUser: true,
                      isLoading: false,
                    });
                  }
                } else {
                  console.log(dataForm["mobileNo"].value?.length === 9);
                  this.setState({ isLoading: false });
                  openNotification({
                    title: "Invalid",
                    message: "Enter valid mobile no.",
                    type: "info",
                    container: "top-center",
                  });
                }
              }}
            />
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isUpdateMobileNo: state.updateMobileNoReducer.isUpdateMobileNo,
    loginUserData: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseUpdateMobileNo: () =>
      dispatch({ type: actionTypes.CLOSE_UPDATE_MOBILE_NO, status: false }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    setUserMobileNo: (payload) =>
      dispatch({ type: actionTypes.SET_SIGNUP_USER_MOBILE, payload }),
  };
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Index));
