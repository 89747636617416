import React from "react";
import "./styles.scss";

const Index = (props) => (
  <>
    <div
      className="bolw_link_content d-flex flex row"
      onClick={props.onClickLink}
    >
      {props.linkContent}
    </div>
  </>
);
export default Index;
