import React from "react";
import "./styles.scss";

const Index = (props) => (
  <>
    <div className="logo_row_wrapper">
      <img src={props.logo} className="logo-image" />
    </div>
  </>
);
export default Index;
