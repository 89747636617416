import React, { Component } from "react";
import { connect } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import { ButtonIcon, JoinFormHeader } from "../../../components";
import { GetIcon } from "../../../config/icon";
import { createNewImpression, getOfferByOfferId } from "../../../service";
import {
  getGeoLocation,
  getInsight,
  getIPAddress,
  getLocationName,
  userDetails,
} from "../../../utils";
import { onClickCloseProfileView } from "../../../store/actions/profileView";
import * as actionTypes from "../../../store/actions/types";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      wizardNo: 1,
      registeredType: 1,
      viewPointWidth: 0,
      viewPointHeight: 0,
      wizardStep: 1,
      dataSource: null,
      bankData: null,
      categoryData: null,
      merchantData: null,
      insightsData: null,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  handleOnScroll = () => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log("scrollTop : " + scrollTop);
    console.log("scrollHeight : " + scrollHeight);
    console.log("clientHeight :" + clientHeight);
  };

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    // this.props.getAllRecentTransfers();
    //this.props.getAllFavouritesTransfers();
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  onClickConfirm = (_step) => {};

  async fetchOfferData() {
    // Fix method name
    let offerData = this.props.offerData;

    if (offerData) {
      let offerId = offerData?.offerId;
      let res = await getOfferByOfferId(offerId);
      if (res && res.status) {
        let offerData = res.data[0];
        let bank = offerData.bank;
        let category = offerData.category;
        let merchant = offerData.merchant;
        console.log({ offerData });

        // await this.creteImpression(
        //     offerId,
        //     bank?.bankId,
        //     category?.categoryId,
        //     merchant?.merchantId
        // );

        console.log("getOfferInsights", res);
        this.setState({
          // isLoading: false,
          dataSource: offerData,
          bankData: bank,
          categoryData: category,
          merchantData: merchant,
        });

        // // //get more Insights
        // let insightsRes = await getOfferInsights(
        //   offerData?.merchant?.merchantId,
        //   offerData?.bank?.bankId,
        //   offerData?.category?.categoryId,
        //   9
        // );
        //
        // console.log("insightsRes", insightsRes);
        // this.setState({
        //   insightsData: insightsRes?.data,
        // });

        // // this.props.onClickViewMoreOfferDetails(
        // //     offerData,
        // //     category,
        // //     bank,
        // //     merchant
        // // );
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async creteImpression(offerId, bankId, merchantId, categoryID) {
    try {
      // Get the geolocation
      const position = await getGeoLocation();
      const { latitude, longitude } = position?.coords;
      // Wait for all promises to resolve
      const [location, ip] = await Promise.all([
        getLocationName(latitude, longitude),
        getIPAddress(),
      ]);
      let state = userDetails();
      //create impression
      const insight = await getInsight();
      console.log("Insight:", insight);
      let impressionRes = await createNewImpression({
        merchantId: merchantId,
        offerId: offerId,
        categoryId: categoryID,
        bankId: bankId,
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
        location: location,
        ip: ip,
        user: state,
        insightId: insight?.insightId,
      });

      // props.setUserInsight({
      //   data: insightBody
      // });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if offerData has changed
    if (prevProps.offerData !== this.props.offerData) {
      try {
        await this.fetchOfferData();
      } catch (error) {
        console.error("Error fetching offer data:", error);
      }
    }
  }

  getTermsAndCondition(_termsAndCondition) {
    if (_termsAndCondition) {
      if (Object.keys(_termsAndCondition)) {
        let bankId = this.state.bankData?.bankId;
        console.log("_termsAndCondition_bankId", bankId);
        if (_termsAndCondition[bankId]?.length > 0) {
          return _termsAndCondition[bankId];
        } else {
          return _termsAndCondition;
        }
      } else {
        console.log("_termsAndCondition", _termsAndCondition);
      }
    }
  }

  render() {
    const { viewPointHeight, dataSource, bankData, categoryData } = this.state;
    const {
      isProfileView,
      offerData,
      onClickFavorite,
      isLogin,
      logInUser,
      isLoading,
    } = this.props;

    return (
      <section
        className={`coo__modal-main-wrapper animate__animated ${
          isProfileView ? "show-me" : "hide-me"
        } `}
      >
        <div className="row justify-content-md-center">
          <div
            className={
              "col-xs-12 col-sm-12 col-md-12 col-lg-8 col-xl-6 col-xxl-6"
            }
          >
            <div
              className={
                "coo_modal-body-content d-flex flex-column fix-536 animate__animated animate__fadeInUp"
              }
              style={{
                borderRadius: "24px",
                height: "auto",
                padding: "42px",
              }}
            >
              <div className={"close-modal-wrapper flex-row"}>
                <ButtonIcon
                  type={"close"}
                  onClick={() => this.props.onClickCloseProfileView()}
                  size={"md"}
                  buttonIcon={GetIcon("close")}
                />
              </div>
              <div
                style={{
                  marginTop: "-42px",
                  marginBottom: "24px",
                }}
              >
                <JoinFormHeader
                  isLogo={false}
                  // wizardNo={""}
                  history={""}
                  header={"Profile View"}
                  subtitle={false}
                />
              </div>

              <>
                <div
                  className={
                    "coo_modal_body d-flex flex-column align-content-center"
                  }
                >
                  <div
                    className={
                      "user-name d-flex flex-row justify-content-between align-items-center"
                    }
                  >
                    <div>
                      <div className={"un__title"}>Name</div>
                      <div className={"un__name"}>{logInUser?.firstName}</div>
                    </div>
                    <div
                      className={
                        "un__icon d-flex flex-row justify-content-center align-items-center align-content-center"
                      }
                    >
                      {GetIcon("rightArrow")}
                    </div>
                  </div>
                  <div
                    className={
                      "user-name d-flex flex-row justify-content-between align-items-center"
                    }
                  >
                    <div>
                      <div className={"un__title"}>Email</div>
                      <div className={"un__name"}></div>
                    </div>
                    <div
                      className={
                        "un__icon d-flex flex-row justify-content-center align-items-center align-content-center"
                      }
                    >
                      {GetIcon("rightArrow")}
                    </div>
                  </div>
                  <div
                    className={
                      "user-name d-flex flex-row justify-content-between align-items-center"
                    }
                  >
                    <div>
                      <div className={"un__title"}>Contact No</div>
                      <div className={"un__name"}>+94{logInUser?.mobileNo}</div>
                    </div>
                    {logInUser?.isMobileVerification && (
                      <div
                        className={
                          "un__verification-icon d-flex flex-row justify-content-center align-items-center align-content-center"
                        }
                      >
                        {GetIcon("check")}
                      </div>
                    )}
                  </div>
                  <div
                    className={
                      "user-name d-flex flex-row justify-content-between align-items-center"
                    }
                  >
                    <div>
                      <div className={"un__title"}>User Type</div>
                      <div className={"un__user-type "}>
                        {logInUser?.userType}
                      </div>
                    </div>
                    <div
                      className={
                        "un__icon d-flex flex-row justify-content-center align-items-center align-content-center"
                      }
                    >
                      {GetIcon("rightArrow")}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isProfileView: state.profileViewReducer.isProfileView,
    logInUser: state.user.data,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickCloseProfileView: () =>
      dispatch({ type: actionTypes.CLOSE_PROFILE_VIEW, status: false }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
