import React from "react";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div className="bank_totla_impression_wrapper d-flex flex row">
      <div className="total_wrapper d-flex flex-column">
        <div className="tw_count">{props.count}</div>
        <div className="tw_title">{props.title}</div>
      </div>

      <div className="impression_wrappper d-flex flex-column">
        <div className="iw_total">{props.total}</div>
        <div className="iw_right_wrapper d-flex flex-row">
          <div className="iw_name">{props.name}</div>
          <div className="iw_icon">{props.icon}</div>
        </div>
      </div>
    </div>
  </>
);
export default Index;
