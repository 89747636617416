import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./styles.scss";
import "./mobile.scss";
import {
  BankSelectionCard,
  BankSelectionCardShimmer,
  Button,
  ButtonIcon,
  SectionHeader,
} from "../../../../components";
import { getInsight } from "../../../../utils";
import { getRecentViewOfferIDByInsight } from "../../../../service";
import Slider from "react-slick";
import {
  RiArrowDownLine,
  RiArrowLeftSLine,
  RiArrowRightLine,
  RiArrowRightSLine,
} from "react-icons/ri";
import { GetIcon } from "../../../../config/icon";

const Index = (props) => {
  //check insight ID
  let sliderRef = useRef(null);

  const dispatch = useDispatch();
  const bankData = useSelector((state) => state.bank.bankData);
  const [isCollapse, setIsCollapse] = useState(false);
  const [settings, setSettings] = useState({
    variableWidth: true,
    className: "slider variable-width",
    dots: true,
    autoplay: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 10,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
  });
  const [isLoading, setIsLoading] = useState(false);

  const next = () => {
    sliderRef.slickNext();
  };
  const previous = () => {
    sliderRef.slickPrev();
  };

  useEffect(() => {
    const fetchInsight = async () => {
      try {
        const data = await getInsight();
        console.log("bankSelctCard:", data);
        if (data?.insightId) {
          let idList = await getRecentViewOfferIDByInsight(data?.insightId);
          const offerIds = idList.data.map((item) => item?.offer_id);
          console.log("recent_view", offerIds);

          // let topOffersList = await getOfferByIdMultiple({
          //     offerIds: offerIds,
          // });
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    fetchInsight();
  }, []);

  useEffect(() => {
    const fetchBank = async () => {
      setIsLoading(true);
      try {
        const data = await getInsight();
        console.log("bankSelctCard:", data);
        setIsLoading(false);
        // if (data?.insightId) {
        //     let idList = await getRecentViewOfferIDByInsight(data?.insightId);
        //     const offerIds = idList.data.map(item => item?.offer_id);
        //     console.log('recent_view', offerIds);
        //     let topOffersList = await getOfferByIdMultiple({
        //         offerIds: offerIds,
        //     });
        //
        //
        // }
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    fetchBank();
  }, []);

  // Example of dynamically changing settings based on screen width
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSettings((prevSettings) => ({
          ...prevSettings,
          slidesToShow: 1,
          infinite: true,
        }));
      } else {
        setSettings((prevSettings) => ({
          ...prevSettings,
          slidesToShow: 3,
          infinite: false,
        }));
      }
    };

    handleResize(); // Run on initial render
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className={`cco_recent-view-offer-wrapper d-flex-flex-column `}>
        <div className={"cco_rvow__title"}>
          <SectionHeader text={"Choose your favorite bank"} size={"md"} />
        </div>

        <div
          className={`cco_rvow__cards-wrp flex-row ${isCollapse ? "more" : ""}`}
        >
          {props.isLoading && bankData?.length === 0 && (
            <>
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
              <BankSelectionCardShimmer />
            </>
          )}

          {!props.isLoading && bankData?.length > 0 && (
            <>
              {bankData?.map((bank) => (
                <BankSelectionCard
                  onClick={() => {
                    props.history.push(
                      `/credit-card-offers/${bank?.bankName}/${bank.bankId}/all/all`
                    );
                  }}
                  key={bank.id}
                  bank={bank}
                  isLoading={props.isLoading}
                />
              ))}
            </>
          )}
        </div>
        <div
          className={
            "d-flex flex-column justify-content-center align-items-center mt-1"
          }
        >
          <Button
            size={"sm x"}
            type={"no-border"}
            text={isCollapse ? "View less" : `View more`}
            rightIcon={true}
            rightIconType={"more"}
            rightIconsize={"md-x"}
            reactIcon={
              isCollapse ? GetIcon("chevronUp") : GetIcon("chevronDown")
            }
            // isLoading={isLoadingMoreOffer}
            onClick={async () => {
              setIsCollapse(!isCollapse);
            }}
          />
        </div>
      </div>
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
