import React, { Component } from "react";
import "./styles.scss";
import "./mobile.scss";

import {
  BankInfoCard,
  BankInfoMobileCard,
  Button,
  ButtonIcon,
  FilterCard,
  FilterColor_1,
  FilterColor_3,
  Input,
  MainHeader,
  OfferCardSmall,
  OfferCardSmallShimmer,
  OfferFavoriteCard,
  PreLoader,
  RecentTag,
  SearchCard,
  SectionHeader,
} from "../../components";
import { connect } from "react-redux";
import {
  getBank,
  getOfferByBanIdCategoryMultiple,
  getOfferByCategoryByBank,
  getOfferByIdMultiple,
  getTopOffersByBank,
} from "../../service";
import * as actionTypes from "../../store/actions/types";
import { getInsight, userDetails } from "../../utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Dropdown, Menu } from "antd";
import {
  getFavoriteOfferByUser,
  getLoginUserDetailsById,
} from "../../service/userApi";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import RestaurantAdCard from "../advertiesment-2";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import Slider from "react-slick";
import {
  getCategoriesWithOffers,
  getCategoryByIdMultiple,
} from "../../service/category";
import { OfferViewModal, ProfileView } from "../modal";
import { GetIcon } from "../../config/icon";
import Scrollbars from "react-custom-scrollbars";

class ViewBank extends Component {
  constructor(props) {
    super(props);
    let _this = this;
    // this.sliderRef = React.useRef(null);
    this.state = {
      viewPointWidth: 0,
      viewPointHeight: 0,
      isLoading: false,
      isLoadingMore: false,
      isFilterClear: false,
      dataSource: null,
      bankData: [],
      topOffersList: [],
      topCategoryList: [],
      topBankOffersList: [],
      topBankCategoryOffersList: [],
      bankList: [],
      categoryList: [],
      categoryData: null,
      merchantData: null,
      sessionUser: null,
      isMobileVerification: false,
      userFavoritesList: [],
      filterOffersList: [],
      oldCategory: "",
      selectCategory: "",
      isViewAll: false,
      isScrollTop: false,
      isViewAllCategoryOffers: false,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: true,
        autoplay: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 10,
        slidesToScroll: 3,
        initialSlide: 0,
        cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round x"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round x"}
              size={"md"}
            />
          </div>
        ),
      },
      dataForm: {
        bank: {
          key: "bank",
          elementType: "input",
          elementConfig: {
            type: "input",
            placeholder: "Bank name type here..",
          },
          value: "",
          validation: {
            required: false,
          },
          valid: false,
          touched: false,
          label: "",
        },
      },
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleOnScroll = this.handleOnScroll.bind(this);
  }

  async componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("scroll", this.handleOnScroll);
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ isLoading: true });

    let sessionUser = userDetails();
    if (sessionUser?.userId && sessionUser.userType) {
      // console.log("sessionUser", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      this.props.userLogged();
      let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);

      if (favoriteData?.status) {
        // console.log("favoriteData", resultCategory);
        this.setState({
          isMobileVerification: favoriteData?.data?.isMobileVerification,
          userFavoritesList: favoriteData?.data[0]?.favorites,
        });
      }
    }
    await this.fetchBankData();
    await this.getBanks();
  }

  handleOnScroll = (e) => {
    let scrollTop =
      (document.documentElement && document.documentElement.scrollTop) ||
      document.body.scrollTop;
    let scrollHeight =
      (document.documentElement && document.documentElement.scrollHeight) ||
      document.body.scrollHeight;
    let clientHeight =
      document.documentElement.clientHeight || window.innerHeight;
    console.log({ scrollTop });
    if (scrollTop <= 100) {
      this.setState({
        isScrollTop: true,
      });
    } else {
      this.setState({
        isScrollTop: false,
      });
    }
  };

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll, true);
    window.removeEventListener("resize", this.updateWindowDimensions, true);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  async fetchBankData() {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ isLoading: true });
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");
    // console.log({ param });
    // console.log(param);

    if (param && param[3] && param[5]) {
      let bankName = param[2];
      let bankId = param[3];
      let categoryName = param[4];
      let categoryId = param[5];

      await this.fetchCategories(bankId);
      let bankData = {
        bankId: bankId,
        bankName: bankName?.replace(/%20/g, " "),
      };
      let categoryData = {
        categoryId: categoryId,
        categoryName: categoryName?.replace(/%20/g, " "),
      };

      this.setState({
        bankData: bankData,
        categoryData: categoryData,
      });

      let topBankCategoryOffersList = await getOfferByBanIdCategoryMultiple({
        bankId: bankId,
        categoryIds: [categoryId],
      });

      this.setState({
        topBankCategoryOffersList: topBankCategoryOffersList?.data,
      });

      try {
        const insight = await getInsight();
        // Additional logic for when the insight is found
        if (insight?.insightId) {
          const response = await getTopOffersByBank(
            insight?.insightId,
            bankId,
            10
          );

          if (
            response.data.top_offers?.length > 0 ||
            response.data.top_offers?.length > 0
          ) {
            let topCategoryList = await getCategoryByIdMultiple({
              categoryIds: response?.data?.top_categories,
            });
            let topOffersList = await getOfferByIdMultiple({
              offerIds: response?.data?.top_offers,
            });

            this.setState({
              topOffersList: topOffersList?.data,
              topCategoryList: topCategoryList?.data,
              isLoading: false,
            });
          }
        }
      } catch (error) {
        console.error(error);
      }

      let categoryIds = [];

      this.state.categoryList.forEach((element) => {
        // if (categoryIds?.length <= 9) {
        categoryIds.push(element?.categoryId);
        // }
      });

      if (categoryIds?.length > 0) {
        let topBankOffersList = await getOfferByBanIdCategoryMultiple({
          bankId: bankId,
          categoryIds: categoryIds,
        });
        console.log({ topBankOffersList });

        this.setState({
          topBankOffersList: topBankOffersList?.data,
          isLoading: false,
        });
      }
    } else {
      //console.error("Invalid path or parameter not found");
    }
  }

  async getBanks() {
    //this.setState({isLoadingBank: true});
    if (this.state.bankList.length === 0) {
      let res = await getBank();
      let bankData = res.status ? await this.syncBank(res.data) : [];
      // this.props.setBank(bankData);
      console.log({ bankData });
      this.setState({
        bankList: bankData,
        isLoadingBank: false,
      });
    }
  }

  async syncBank(res) {
    let resList = [];
    for (let data of res) {
      resList.push({
        ...data,
        isActive: false,
      });
    }
    return resList;
  }

  async fetchBankOffers() {
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");

    if (param && param[3] && param[5]) {
      let bankId = param[3];

      let categoryIds = [];

      this.state.categoryList.forEach((element) => {
        // if (categoryIds?.length <= 4) {
        categoryIds.push(element?.categoryId);
        // }
      });
      if (categoryIds?.length > 0) {
        let topBankOffersList = await getOfferByBanIdCategoryMultiple({
          bankId: bankId,
          categoryIds: categoryIds,
        });

        this.setState({
          topBankOffersList: topBankOffersList?.data,
        });
      } else {
        this.setState({
          topBankOffersList: [],
        });
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async fetchFilterOffers(categoryId) {
    document.querySelector("body").scrollTo(0, 0);
    this.setState({ isLoading: true });
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");

    if (param && param[3] && param[5]) {
      let bankId = param[3];

      let categoryIds = [categoryId];

      if (categoryIds?.length > 0) {
        let filterResult = await getOfferByBanIdCategoryMultiple({
          bankId: bankId,
          categoryIds: categoryIds,
        });

        this.setState({
          filterOffersList: filterResult?.data,
          isLoading: false,
        });
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async fetchFilterOffersCategory(categoryId, pageNo) {
    this.setState({ isLoadingMore: true });
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");

    if (param && param[3] && categoryId && pageNo) {
      let bankId = param[3];

      let filterResult = await getOfferByCategoryByBank(
        bankId,
        categoryId,
        pageNo,
        6
      );

      let updateOfferList = [...this.state.topBankOffersList];
      console.log({ updateOfferList });
      let categoryOffers = updateOfferList.find(
        (x) => x.categoryId === categoryId
      );
      console.log({ categoryOffers });
      if (categoryOffers) {
        categoryOffers.offers.push(...filterResult.data);
        this.setState({
          topBankOffersList: updateOfferList,
          isLoadingMore: false,
        });
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname) {
        await this.fetchBankData();
        await this.fetchBankOffers();
      }
    }
    if (prevState.categoryList !== this.state.categoryList) {
      if (this.state.categoryList?.length > 0) {
        await this.fetchBankOffers();
      }
    }
  }

  async fetchCategories(bankId) {
    let resultCategory = await getCategoriesWithOffers(bankId);
    console.log({ resultCategory });

    this.setState({
      categoryList: resultCategory?.data,
    });
  }

  onClickSelectBank = async (bankId) => {
    const { bankList } = this.state;
    await this.setState((prevState) => ({
      bankList: prevState.bankList.map(
        (bank) =>
          bank.bankId === bankId
            ? { ...bank, isActive: true }
            : { ...bank, isActive: false } // Update category object
      ),
    }));
  };

  getLocationName = (location) => {
    for (let i = 0; i < location?.length; i++) {
      if (location[i].isActive) {
        return location[i].value;
      }
    }
  };

  checkIsFavorite = (offerId) => {
    const { userFavoritesList } = this.state;
    let isAvailable = false;

    if (userFavoritesList && userFavoritesList.length > 0) {
      for (let i in userFavoritesList) {
        if (userFavoritesList[i]?.offerId === offerId) {
          isAvailable = true;
        }
      }
    }
    return isAvailable;
  };

  handleNavigate = () => {
    this.props.history.push("/");
    this.props.setFilterOptions();
  };

  inputHandleChange = (event, inputIdentity) => {
    const updateForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateForm[inputIdentity],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.touched = true;
    updateForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateForm) {
      formIsValid = updateForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateForm, formIsValid: formIsValid });
  };

  clearAll() {
    const updateForm = { ...this.state.dataForm };
    for (let key in updateForm) {
      updateForm[key].value = "";
      updateForm[key].valid = false;
      updateForm[key].touched = false;
    }
    this.setState({ dataForm: updateForm });
  }

  render() {
    const {
      dataSource,
      bankData,
      dataForm,
      categoryData,
      sessionUser,
      isFavoriteLoading,
      viewPointHeight,
      viewPointWidth,
      activeBankList,
      settings,
      isLoading,
      isLoadingMore,
    } = this.state;

    const banks = (
      <div className={"filter-list"} style={{ zIndex: 1110 }}>
        <div style={{ marginBottom: "8px" }}>
          <Input
            elementConfig={dataForm.bank.elementConfig}
            elementType={dataForm.bank.elementType}
            required={dataForm.bank.validation.required}
            maxLength={dataForm.bank.validation.maxLength}
            invalidReason={dataForm.bank.invalidReason}
            isinvalid={!dataForm.bank.valid}
            touched={dataForm.bank.touched}
            value={dataForm.bank.value}
            size={"sm-search"}
            verificationStatus={dataForm.bank.valid}
            showVerification={!!dataForm.bank.value}
            margin={"m-t-8"}
            label={dataForm.bank.label}
            onClickClear={() => this.clearAll()}
            changed={(event) =>
              this.inputHandleChange(event, dataForm.bank.key)
            }
          />
        </div>
        <div>
          <Scrollbars
            style={{
              height:
                // viewPointWidth <= 1600
                //   ? viewPointHeight - 530
                viewPointHeight - 500,
            }}
            thumbMinSize={30}
            universal={true}
            autoHide
          >
            <>
              {this.state.bankList
                ?.filter((item) => {
                  if (dataForm["bank"].value === "") {
                    return item; // Corrected the return statement
                  } else if (
                    item.bankName
                      ?.toLowerCase()
                      .includes(dataForm["bank"].value.toLowerCase())
                  ) {
                    return item; // Corrected the return statement
                  }
                })
                .sort((a, b) => {
                  // Sort to place active items at the top
                  return b.isActive - a.isActive;
                })
                .map((item, index) => (
                  <SearchCard
                    type={"bank"}
                    size={"sm"}
                    imageType={"image"}
                    imgUrl={item.thumbnailURL}
                    remove={false}
                    isSelect={item.isActive}
                    select={false}
                    format={"select-box"}
                    text={item.bankName}
                    onClick={async () => {
                      await this.onClickSelectBank(item?.bankId);
                      // await this.getOfferFilterByBankId(
                      //   item.bankId,
                      //   item.bankName,
                      //   item.logoURL,
                      //   item.thumbnailURL
                      // );
                      this.props.history.push(
                        `/credit-card-offers/${item?.bankName}/${item?.bankId}/${categoryData.categoryName}/${categoryData.categoryId}`
                      );
                    }}
                  />
                ))}
            </>
          </Scrollbars>
        </div>
      </div>
    );

    const profileMenu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer">
            Setting
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => this.props.onClickOpenProfileView()}
          >
            Portal
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onClickLogOut()} rel="noopener noreferrer">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <>
        {/*create new event*/}
        <Helmet>
          <title>
            {`${bankData?.bankName || "Top"} Credit Card Offers - ${
              categoryData?.categoryName || "Best Deals"
            } in Sri Lanka`}
          </title>
          <meta
            name="description"
            content={`Explore ${
              bankData?.bankName || "the leading bank's"
            } credit card offers and promotions in Sri Lanka. Discover ${
              categoryData?.categoryName || "exciting deals"
            } and exclusive discounts on CreditCardOffers.lk.`}
          />
          <link
            rel="canonical"
            href={`https://www.creditcardoffers.lk/${
              dataSource?.offerId || "offers"
            }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }`}
          />
        </Helmet>

        <Helmet>
          <script type="application/ld+json">
            {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${
                    bankData?.bankName || "Top Bank"
                  } Credit Card Offers - ${
              categoryData?.categoryName || "Best Deals"
            } in Sri Lanka",
                  "url": "https://www.creditcardoffers.lk/${
                    dataSource?.offerId || "offers"
                  }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }",
                  "description": "Discover ${
                    bankData?.bankName || "top banks"
                  } credit card offers and exclusive ${
              categoryData?.categoryName || "promotions"
            } in Sri Lanka. Stay updated with the best deals on CreditCardOffers.lk.",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://www.creditcardoffers.lk/${
                      dataSource?.offerId || "offers"
                    }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }"
                  }
                }
             `}
          </script>
        </Helmet>

        <ProfileView />
        <OfferViewModal />
        <MainHeader
          logInUser={
            sessionUser && sessionUser?.userId && sessionUser?.userType
              ? sessionUser
              : null
          }
          history={this.props.history}
          privateUser={require("../../assets/img/default-avatar.jpg")}
          profileMenu={profileMenu}
          onClickOpenNewOffer={() => this.props.onClickOpenNewOffer()}
          isLogin={this.props.isLogin}
          onClickSignUp={() => this.props.onClickOpenSignUp()}
          onClickLogOut={() => this.onClickLogOut()}
          onClickSignIn={() => this.props.onClickOpenSignIn()}
        />

        <div className={"console-inner-wrapper view-offer"} id={"viewOfferId"}>
          <PreLoader isLoading={isLoading} />
          <div className={"top-wrapper"}>
            <div className={"tw__filter-1"}>
              <FilterColor_3 />
            </div>
            <div className={"tw__img-wrap"}>
              <img src={require("../../assets/img/Group 5_2.png")} />
            </div>
            <div className={"tw__filter-2"}>
              <FilterColor_1 />
            </div>
            <div className={"tw__filter-3"}>
              <FilterColor_3 />
            </div>
          </div>

          <div className={"container"}>
            <div className={"row"}>
              {/*======== offer details ========*/}
              <div className={"col-12  "}>
                <div className={"bank-view-page-wrapper d-flex flex-column"}>
                  <div className={"Breadcrumb"}>
                    <div className="home-url" onClick={this.handleNavigate}>
                      Home
                    </div>
                    /
                    <Dropdown overlay={banks}>
                      <div>
                        {bankData?.bankName}
                        {GetIcon("chevronDown")}{" "}
                      </div>
                    </Dropdown>
                    {/* / {bankData?.bankId?.substring(0, 17).trimEnd() + "..."} */}
                  </div>

                  <div className={"row"}>
                    {/*==== OFFER VIEW PAGE====*/}
                    <div className={"col-3 bvpw__left-wrp"}>
                      <div className="bank-card-wrapper">
                        <BankInfoCard
                          bankId={bankData?.bankId}
                          logo={bankData?.logoURL}
                          name={bankData?.bankName}
                          categoryName={categoryData?.categoryName}
                          categoryId={categoryData?.categoryId}
                          topOffersList={this.state.topOffersList}
                          favCount={"1K"}
                        />
                      </div>
                      <div style={{ marginBottom: "217px" }}>
                        <RestaurantAdCard
                          type={"text"}
                          title={"ooge.lk"}
                          description={
                            "Revolutionize your restaurant or hotel management with ooge."
                          }
                          btnName={"Learn More"}
                        />
                      </div>
                    </div>

                    <div className={"col-9  bvpw__right-wrp"}>
                      {/*======== mobile bank card start ========*/}
                      <div className={"mobile-bank-card"}>
                        {/* <div
                              className={
                                "d-flex flex-row justify-content-between align-items-center"
                              }
                            >
                              <div
                                className={
                                  "d-flex flex-row  align-items-center"
                                }
                              >
                                <div className={"mbcw__logo"}>
                                  <img
                                    src={bankData?.logoURL}
                                    alt={"logo"}
                                    className="mbcw_logo"
                                  />
                                </div>
                                <div className={"mbcw__name"}>HSBC Bank</div>
                              </div>
                              <div
                                className={
                                  "d-flex flex-row justify-content-between"
                                }
                              >
                                <div
                                  className={
                                    "mbcw__total-wrapper d-flex flex-column align-items-center justify-content-center"
                                  }
                                >
                                  <div className={"mbcw__tw-count"}>150</div>
                                  <div className={"mbcw__tw-title"}>
                                    Total Offers
                                  </div>
                                </div>
                                <div
                                  className={
                                    "mbcw__impression-wrapper d-flex flex-column align-items-center justify-content-center"
                                  }
                                >
                                  <div className={"mbcw__iw-count"}>50</div>
                                  <div className={"mbcw__iw-title"}>
                                    Impressions
                                  </div>
                                </div>
                              </div>
                            </div> */}
                        <BankInfoMobileCard
                          bankId={bankData?.bankId}
                          logo={bankData?.logoURL}
                          name={bankData?.bankName}
                          categoryName={categoryData?.categoryName}
                          categoryId={categoryData?.categoryId}
                          topOffersList={this.state.topOffersList}
                        />
                      </div>
                      {/*======== mobile bank card end ========*/}
                      {!isLoading && (
                        <>
                          {/*======== recent-view-tag ========*/}
                          <div
                            className={`recent-views-wrapper d-flex flex-column ${
                              this.state.isScrollTop
                                ? "fix-top active-shadow"
                                : ""
                            }`}
                          >
                            {this.state.categoryList?.length > 0 && (
                              <div className={"rvw__title"}>Recent view</div>
                            )}

                            <div
                              className={"rvw__recent-cards d-flex flex-row"}
                            >
                              {this.state.categoryList?.length > 4 ? (
                                <Slider
                                  {...settings}
                                  // ref={(slider) => {
                                  //   this.sliderRef = slider;
                                  // }}
                                  className={
                                    "rpmw__slider d-flex flex-row justify-content-center pt-3"
                                  }
                                >
                                  {(this.state.categoryList || [])
                                    .sort((a, b) => {
                                      // Sort to place active items at the top
                                      if (
                                        categoryData?.categoryId ===
                                        a.categoryId
                                      )
                                        return -1;
                                      if (
                                        categoryData?.categoryId ===
                                        b.categoryId
                                      )
                                        return 1;
                                      return 0;
                                    })
                                    .map((item, index) => (
                                      <RecentTag
                                        key={index} // Always provide a unique key when mapping
                                        iconName={
                                          categoryData?.categoryId ===
                                          item.categoryId
                                            ? "close"
                                            : item?.iconName
                                        }
                                        isActive={
                                          categoryData?.categoryId ===
                                          item.categoryId
                                        }
                                        title={item?.categoryName}
                                        onClick={async () => {
                                          const updatedCategoryData = {
                                            categoryName: item.categoryName,
                                            categoryId: item.categoryId,
                                          };

                                          this.setState(
                                            {
                                              selectCategory: item.categoryId,
                                              oldCategory:
                                                this.state.oldCategory === ""
                                                  ? categoryData
                                                  : this.state.oldCategory,
                                              isFilterClear: true,
                                              categoryData: updatedCategoryData,
                                            },
                                            () => {
                                              this.props.history.push(
                                                `/credit-card-offers/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                              );
                                            }
                                          );
                                          await this.fetchFilterOffers(
                                            item.categoryId
                                          );
                                        }}
                                      />
                                    ))}
                                </Slider>
                              ) : (
                                <div className="rpmw__slider d-flex flex-row justify-content-center gap-2">
                                  {(this.state.categoryList || [])
                                    .sort((a, b) => {
                                      // Sort to place active items at the top
                                      if (
                                        categoryData?.categoryId ===
                                        a.categoryId
                                      )
                                        return -1;
                                      if (
                                        categoryData?.categoryId ===
                                        b.categoryId
                                      )
                                        return 1;
                                      return 0;
                                    })
                                    .map((item, index) => (
                                      <RecentTag
                                        key={index} // Always provide a unique key when mapping
                                        iconName={
                                          categoryData?.categoryId ===
                                          item.categoryId
                                            ? "close"
                                            : item?.iconName
                                        }
                                        isActive={
                                          categoryData?.categoryId ===
                                          item.categoryId
                                        }
                                        title={item?.categoryName}
                                        onClick={async () => {
                                          const updatedCategoryData = {
                                            categoryName: item.categoryName,
                                            categoryId: item.categoryId,
                                          };

                                          this.setState(
                                            {
                                              selectCategory: item.categoryId,
                                              oldCategory:
                                                this.state.oldCategory === ""
                                                  ? categoryData
                                                  : this.state.oldCategory,
                                              isFilterClear: true,
                                              categoryData: updatedCategoryData,
                                            },
                                            () => {
                                              this.props.history.push(
                                                `/credit-card-offers/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                              );
                                            }
                                          );
                                          await this.fetchFilterOffers(
                                            item.categoryId
                                          );
                                        }}
                                      />
                                    ))}
                                </div>
                              )}
                            </div>
                          </div>

                          {this.state.filterOffersList?.length === 0 &&
                            !this.state.isFilterClear && (
                              <>
                                {/*======== favorite offers ========*/}
                                {!this.state.isViewAllCategoryOffers && (
                                  <div
                                    className={
                                      "recent-view-cards-wrapper xl d-flex flex-column"
                                    }
                                  >
                                    {(this.state.topOffersList?.length > 0 ||
                                      this.state.userFavoritesList?.length >
                                        0) && (
                                      <>
                                        <div className={"rvcw__border"}>
                                          <div
                                            className={
                                              "rvcw__header d-flex flex-row"
                                            }
                                          >
                                            <div className={"rvcw__h-title"}>
                                              <SectionHeader
                                                text={
                                                  this.state.topOffersList
                                                    ?.length > 0
                                                    ? "Your recently viewed offers"
                                                    : "Favorite"
                                                }
                                                size={"md"}
                                              />
                                            </div>
                                            <div
                                              className={"rvcw__h-action"}
                                              onClick={() =>
                                                this.setState({
                                                  isViewAll:
                                                    !this.state.isViewAll,
                                                })
                                              }
                                            >
                                              {this.state.isViewAll
                                                ? "Less"
                                                : "View all"}
                                            </div>
                                          </div>
                                          {/* user favorites */}
                                          {this.state.topOffersList?.length ===
                                            0 && (
                                            <>
                                              {!this.state.isViewAll && (
                                                <div
                                                  className={`rvcw__offer-list ${
                                                    this.state
                                                      .isViewAllCategoryOffers
                                                      ? "d-flex flex-column"
                                                      : ""
                                                  }`}
                                                >
                                                  {(
                                                    this.state
                                                      .userFavoritesList || []
                                                  )
                                                    .slice(0, 3)
                                                    .map((item, index) => (
                                                      <OfferFavoriteCard
                                                        status={
                                                          item?.offer?.status
                                                        }
                                                        location={this.getLocationName(
                                                          item?.offer?.location
                                                        )}
                                                        bannerUrl={
                                                          item?.offer
                                                            ?.bannerImageURL
                                                        }
                                                        logoUrl={
                                                          item?.merchant
                                                            ?.logoURL
                                                        }
                                                        merchantName={
                                                          item?.merchant
                                                            ?.merchantName
                                                            .length > 20
                                                            ? item?.merchant?.merchantName
                                                                ?.substring(
                                                                  0,
                                                                  20
                                                                )
                                                                .trimEnd() +
                                                              "..."
                                                            : item?.merchant
                                                                ?.merchantName
                                                        }
                                                        header={
                                                          item?.offer?.header
                                                            ?.length > 20
                                                            ? item?.offer?.header
                                                                ?.substring(
                                                                  0,
                                                                  20
                                                                )
                                                                .trimEnd() +
                                                              "..."
                                                            : item?.offer
                                                                ?.header
                                                        }
                                                        isLoading={isLoading}
                                                        onClick={() =>
                                                          // this.props.onClickViewMoreOfferDetails(
                                                          //   item.offer,
                                                          //   item?.category,
                                                          //   item?.bank
                                                          // )
                                                          this.props.history.push(
                                                            `/credit-card-offers/${item.offer.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                                          )
                                                        }
                                                      />
                                                    ))}
                                                </div>
                                              )}
                                              {this.state.isViewAll && (
                                                <div
                                                  className={`rvcw__offer-list ${
                                                    this.state
                                                      .isViewAllCategoryOffers
                                                      ? "d-flex flex-column"
                                                      : ""
                                                  }`}
                                                >
                                                  {(
                                                    this.state
                                                      .userFavoritesList || []
                                                  ).map((item, index) => (
                                                    <OfferCardSmall
                                                      key={item?.offer?.offerId}
                                                      id={`${item?.offer?.offerId}`}
                                                      animate={
                                                        "animate__fadeIn"
                                                      }
                                                      size={"sm"}
                                                      type={"favourite"}
                                                      isFavorites={this.checkIsFavorite(
                                                        item?.offer?.offerId
                                                      )}
                                                      isLoading={isLoading}
                                                      data={{
                                                        ...item?.offer,
                                                        bank: item?.bank,
                                                        category:
                                                          item?.category,
                                                        merchant:
                                                          item?.merchant,
                                                      }}
                                                      onClick={() =>
                                                        // this.props.onClickViewMoreOfferDetails(
                                                        //   item.offer,
                                                        //   item?.category,
                                                        //   item?.bank
                                                        // )
                                                        this.props.history.push(
                                                          `/credit-card-offers/${item.offer.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                                        )
                                                      }
                                                    />
                                                  ))}
                                                </div>
                                              )}
                                            </>
                                          )}
                                          {/* top offers */}
                                          {this.state.topOffersList?.length >
                                            0 && (
                                            <>
                                              {!this.state.isViewAll && (
                                                <div
                                                  className={`rvcw__offer-list ${
                                                    this.state
                                                      .isViewAllCategoryOffers
                                                      ? "d-flex flex-column"
                                                      : ""
                                                  }`}
                                                >
                                                  {(
                                                    this.state.topOffersList ||
                                                    []
                                                  )
                                                    .slice(0, 3)
                                                    .map((item, index) => (
                                                      <OfferFavoriteCard
                                                        status={item?.status}
                                                        location={this.getLocationName(
                                                          item?.location
                                                        )}
                                                        bannerUrl={
                                                          item?.bannerImageURL
                                                        }
                                                        logoUrl={
                                                          item?.merchantData
                                                            ?.logoURL
                                                        }
                                                        merchantName={
                                                          item?.merchantData
                                                            ?.merchantName
                                                            .length > 20
                                                            ? item?.merchantData?.merchantName
                                                                ?.substring(
                                                                  0,
                                                                  20
                                                                )
                                                                .trimEnd() +
                                                              "..."
                                                            : item?.merchantData
                                                                ?.merchantName
                                                        }
                                                        header={
                                                          item?.header?.length >
                                                          20
                                                            ? item?.header
                                                                ?.substring(
                                                                  0,
                                                                  20
                                                                )
                                                                .trimEnd() +
                                                              "..."
                                                            : item?.header
                                                        }
                                                        // isLoading={
                                                        //   isFavoriteLoading
                                                        // }
                                                        onClick={() =>
                                                          // this.props.onClickViewMoreOfferDetails(
                                                          //   item,
                                                          //   item?.category,
                                                          //   item?.bank
                                                          // )
                                                          this.props.history.push(
                                                            `/credit-card-offers/${item.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                                          )
                                                        }
                                                      />
                                                    ))}
                                                </div>
                                              )}
                                              {this.state.isViewAll && (
                                                <div
                                                  className={`rvcw__offer-list ${
                                                    this.state
                                                      .isViewAllCategoryOffers
                                                      ? "d-flex flex-column"
                                                      : ""
                                                  }`}
                                                >
                                                  {(
                                                    this.state.topOffersList ||
                                                    []
                                                  ).map((item, index) => (
                                                    <OfferCardSmall
                                                      key={item?.offerId}
                                                      id={`${item?.offerId}`}
                                                      animate={
                                                        "animate__fadeIn"
                                                      }
                                                      size={"sm"}
                                                      type={"favourite"}
                                                      isFavorites={this.checkIsFavorite(
                                                        item?.offerId
                                                      )}
                                                      isLoading={isLoading}
                                                      data={item}
                                                      onClick={() =>
                                                        // this.props.onClickViewMoreOfferDetails(
                                                        //   item,
                                                        //   item?.category,
                                                        //   item?.bank
                                                        // )
                                                        this.props.history.push(
                                                          `/credit-card-offers/${item.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                                        )
                                                      }
                                                    />
                                                  ))}
                                                </div>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </>
                                    )}
                                  </div>
                                )}

                                {/* recent view category */}

                                {!this.state.isViewAllCategoryOffers &&
                                  this.state.topCategoryList?.length > 0 && (
                                    <div
                                      className={
                                        "recent-view-cards-wrapper d-flex flex-column mb-5"
                                      }
                                    >
                                      <>
                                        <div className={"rvcw__border"}>
                                          <div
                                            className={
                                              "rvcw__header d-flex flex-row justify-content-start"
                                            }
                                          >
                                            <div className={"rvcw__h-title"}>
                                              <SectionHeader
                                                text={"Recent view"}
                                                size={"x-sm"}
                                              />
                                            </div>
                                          </div>
                                          <div
                                            className={"rvcw__category-list"}
                                          >
                                            {(
                                              this.state.topCategoryList || []
                                            ).map((item, index) => (
                                              <div className="d-flex">
                                                <RecentTag
                                                  iconName={
                                                    categoryData?.categoryId ===
                                                    item.categoryId
                                                      ? "close"
                                                      : item?.iconName
                                                  }
                                                  isActive={
                                                    categoryData?.categoryId ===
                                                    item.categoryId
                                                  }
                                                  title={item?.categoryName}
                                                  onClick={async () => {
                                                    const updatedCategoryData =
                                                      {
                                                        categoryName:
                                                          item.categoryName,
                                                        categoryId:
                                                          item.categoryId,
                                                      };

                                                    this.setState(
                                                      {
                                                        selectCategory:
                                                          item.categoryId,
                                                        oldCategory:
                                                          this.state
                                                            .oldCategory === ""
                                                            ? categoryData
                                                            : this.state
                                                                .oldCategory,
                                                        isFilterClear: true,
                                                        categoryData:
                                                          updatedCategoryData,
                                                      },
                                                      () => {
                                                        this.props.history.push(
                                                          `/credit-card-offers/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                                        );
                                                      }
                                                    );
                                                    await this.fetchFilterOffers(
                                                      item.categoryId
                                                    );
                                                  }}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  )}

                                {/* offers for bank top categories */}
                                {this.state.topBankOffersList?.length > 0 && (
                                  <div
                                    className={
                                      "recent-view-cards-wrapper d-flex flex-column"
                                    }
                                  >
                                    {(this.state.topBankOffersList || []).map(
                                      (item, index) => (
                                        <>
                                          <div className={"rvcw__border"}>
                                            <div
                                              className={
                                                "rvcw__header d-flex flex-row justify-content-start"
                                              }
                                            >
                                              <div className="rcvw__h-icon">
                                                {GetIcon(
                                                  categoryData?.categoryId ===
                                                    item.categoryId
                                                    ? ""
                                                    : item?.iconName
                                                )}
                                              </div>
                                              <div className={"rvcw__h-title "}>
                                                <SectionHeader
                                                  text={item?.categoryName}
                                                  size={"md"}
                                                />
                                              </div>
                                              {/* <div
                                              className={"rvcw__h-action"}
                                              onClick={async () => {
                                                this.setState({
                                                  isViewAllCategoryOffers:
                                                    !this.state
                                                      .isViewAllCategoryOffers,
                                                });
                                                const updatedCategoryData = {
                                                  categoryName:
                                                    item.categoryName,
                                                  categoryId: item.categoryId,
                                                };

                                                this.setState(
                                                  {
                                                    selectCategory:
                                                      item.categoryId,
                                                    oldCategory:
                                                      this.state.oldCategory ===
                                                      ""
                                                        ? categoryData
                                                        : this.state
                                                            .oldCategory,
                                                    isFilterClear: true,
                                                    categoryData:
                                                      updatedCategoryData,
                                                  },
                                                  () => {
                                                    this.props.history.push(
                                                      `/bank-view/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                                    );
                                                  }
                                                );
                                                await this.fetchFilterOffers(
                                                  item.categoryId
                                                );
                                              }}
                                            >
                                              View all
                                            </div> */}
                                            </div>
                                            <div
                                              className={`rvcw__offer-list ${
                                                this.state
                                                  .isViewAllCategoryOffers
                                                  ? "d-flex flex-column"
                                                  : ""
                                              }`}
                                            >
                                              {item?.offers?.length > 0 && (
                                                <>
                                                  {!this.state
                                                    .isViewAllCategoryOffers &&
                                                    (item?.offers || []).map(
                                                      (item, index) => (
                                                        <div className="d-flex">
                                                          <OfferCardSmall
                                                            key={item?.offerId}
                                                            id={`${item?.offerId}`}
                                                            animate={
                                                              "animate__fadeIn"
                                                            }
                                                            size={"sm"}
                                                            type={"favourite"}
                                                            isFavorites={this.checkIsFavorite(
                                                              item?.offerId
                                                            )}
                                                            isLoading={
                                                              isLoading
                                                            }
                                                            data={item}
                                                            onClick={() =>
                                                              // this.props.onClickViewMoreOfferDetails(
                                                              //   item,
                                                              //   item?.category,
                                                              //   item?.bank
                                                              // )
                                                              this.props.history.push(
                                                                `/credit-card-offers/${item.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                                              )
                                                            }
                                                          />
                                                        </div>
                                                      )
                                                    )}

                                                  {/* {this.state
                                                  .isViewAllCategoryOffers &&
                                                  (item?.offers || []).map(
                                                    (item, index) => (
                                                      <div className="d-flex">
                                                        <OfferCard
                                                          key={item.offerId}
                                                          id={`${item.offerId}`}
                                                          animate={
                                                            "animate__fadeIn"
                                                          }
                                                          isFavorites={this.checkIsFavorite(
                                                            item.offerId
                                                          )}
                                                          isLoading={
                                                            isFavoriteLoading
                                                          }
                                                          data={item}
                                                          categoryData={[
                                                            item?.category,
                                                          ]}
                                                          bankData={[
                                                            item?.bank,
                                                          ]}
                                                          onClick={() =>
                                                            this.props.onClickViewMoreOfferDetails(
                                                              item,
                                                              item?.category,
                                                              item?.bank
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  )} */}
                                                </>
                                              )}
                                            </div>
                                            {!isLoading &&
                                              item.totalCount -
                                                item?.offers?.length >
                                                0 && (
                                                <div
                                                  className={
                                                    "console__load-more-wrp d-flex align-items-center justify-content-center mt-3 "
                                                  }
                                                >
                                                  <Button
                                                    size={"xl x"}
                                                    type={"light"}
                                                    isLoading={isLoadingMore}
                                                    onClick={async () => {
                                                      if (
                                                        item?.offers?.length > 0
                                                      ) {
                                                        let pageNo =
                                                          item?.offers?.length /
                                                            6 +
                                                          1;
                                                        await this.fetchFilterOffersCategory(
                                                          item?.categoryId,
                                                          pageNo
                                                        );
                                                      }
                                                    }}
                                                    text={`Load more offers (${
                                                      item.totalCount -
                                                      item?.offers?.length
                                                    })`}
                                                  />
                                                </div>
                                              )}
                                          </div>
                                        </>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            )}
                          {(this.state.filterOffersList?.length > 0 ||
                            this.state.isFilterClear) && (
                            <>
                              {/* offers for bank top categories */}

                              <div
                                className={
                                  "recent-view-cards-wrapper xl d-flex flex-column"
                                }
                              >
                                {this.state.filterOffersList?.length === 0 &&
                                  this.state.isFilterClear && (
                                    <>
                                      <div className={"rvcw__border"}>
                                        <div
                                          className={
                                            "rvcw__header d-flex flex-row mt-2 justify-content-start"
                                          }
                                        >
                                          <div className={"rvcw__h-title"}>
                                            <SectionHeader
                                              text={categoryData?.categoryName}
                                              size={"md"}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                {this.state.filterOffersList?.length > 0 &&
                                  (this.state.filterOffersList || []).map(
                                    (item, index) => (
                                      <>
                                        <div className={"rvcw__border"}>
                                          <div
                                            className={
                                              "rvcw__header d-flex flex-row mt-2 justify-content-start"
                                            }
                                          >
                                            <div className="rcvw__h-icon">
                                              {GetIcon(
                                                categoryData?.categoryId ===
                                                  item.categoryId
                                                  ? ""
                                                  : item?.iconName
                                              )}
                                            </div>
                                            <div className={"rvcw__h-title"}>
                                              <SectionHeader
                                                text={item?.categoryName}
                                                size={"md"}
                                              />
                                            </div>
                                            {/* <div
                                            className={"rvcw__h-action"}
                                            onClick={async () => {
                                              this.setState({
                                                isViewAllCategoryOffers:
                                                  !this.state
                                                    .isViewAllCategoryOffers,
                                              });
                                              const updatedCategoryData = {
                                                categoryName: item.categoryName,
                                                categoryId: item.categoryId,
                                              };

                                              this.setState(
                                                {
                                                  selectCategory:
                                                    item.categoryId,
                                                  oldCategory:
                                                    this.state.oldCategory ===
                                                    ""
                                                      ? categoryData
                                                      : this.state.oldCategory,
                                                  isFilterClear: true,
                                                  categoryData:
                                                    updatedCategoryData,
                                                },
                                                () => {
                                                  this.props.history.push(
                                                    `/bank-view/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                                  );
                                                }
                                              );
                                              await this.fetchFilterOffers(
                                                item.categoryId
                                              );
                                            }}
                                          >
                                            View all
                                          </div> */}
                                          </div>
                                          <div
                                            className={`rvcw__offer-list ${
                                              this.state.isViewAllCategoryOffers
                                                ? "d-flex flex-column"
                                                : ""
                                            }`}
                                          >
                                            {item?.offers?.length > 0 && (
                                              <>
                                                {!this.state
                                                  .isViewAllCategoryOffers &&
                                                  (item?.offers || []).map(
                                                    (offerItem, index) => (
                                                      <div className="d-flex">
                                                        <OfferCardSmall
                                                          key={
                                                            offerItem?.offerId
                                                          }
                                                          id={`${offerItem?.offerId}`}
                                                          animate={
                                                            "animate__fadeIn"
                                                          }
                                                          size={"sm"}
                                                          type={"favourite"}
                                                          isFavorites={this.checkIsFavorite(
                                                            offerItem?.offerId
                                                          )}
                                                          isLoading={isLoading}
                                                          data={offerItem}
                                                          onClick={() =>
                                                            // this.props.onClickViewMoreOfferDetails(
                                                            //   offerItem,
                                                            //   offerItem?.category,
                                                            //   offerItem?.bank
                                                            // )
                                                            this.props.history.push(
                                                              `/credit-card-offers/${offerItem.offerId}/${offerItem.bank?.bankName}/${offerItem.category?.categoryName}`
                                                            )
                                                          }
                                                        />
                                                      </div>
                                                    )
                                                  )}

                                                {/* {this.state
                                                .isViewAllCategoryOffers &&
                                                (item?.offers || []).map(
                                                  (offer, index) => (
                                                    <div className="d-flex">
                                                      <OfferCard
                                                        key={offer?.offerId}
                                                        id={`${offer?.offerId}`}
                                                        animate={
                                                          "animate__fadeIn"
                                                        }
                                                        isFavorites={this.checkIsFavorite(
                                                          offer?.offerId
                                                        )}
                                                        isLoading={
                                                          isFavoriteLoading
                                                        }
                                                        data={offer}
                                                        categoryData={[
                                                          offer?.category,
                                                        ]}
                                                        bankData={[offer?.bank]}
                                                        onClick={() =>
                                                          this.props.onClickViewMoreOfferDetails(
                                                            offer,
                                                            offer?.category,
                                                            offer?.bank
                                                          )
                                                        }
                                                      />
                                                    </div>
                                                  )
                                                )} */}
                                              </>
                                            )}
                                          </div>
                                          {!isLoading &&
                                            item.totalCount -
                                              item?.offers?.length >
                                              0 && (
                                              <div
                                                className={
                                                  "console__load-more-wrp d-flex align-items-center justify-content-center mt-3 "
                                                }
                                              >
                                                <Button
                                                  size={"xl x"}
                                                  type={"light"}
                                                  isLoading={isLoadingMore}
                                                  onClick={async () => {
                                                    if (
                                                      item?.offers?.length > 0
                                                    ) {
                                                      let pageNo =
                                                        item?.offers?.length /
                                                          6 +
                                                        1;
                                                      await this.fetchFilterOffersCategory(
                                                        item?.categoryId,
                                                        pageNo
                                                      );
                                                    }
                                                  }}
                                                  text={`Load more offers (${
                                                    item.totalCount -
                                                    item?.offers?.length
                                                  })`}
                                                />
                                              </div>
                                            )}
                                        </div>
                                      </>
                                    )
                                  )}
                              </div>
                            </>
                          )}
                          {(this.state.isFilterClear ||
                            this.state.filterOffersList?.length > 0) && (
                            <div
                              className={`clear-btn animate__animated ${
                                this.state.isFilterClear
                                  ? "animate__fadeInUp"
                                  : ""
                              }`}
                            >
                              {/* <ButtonIcon
                                type={"close"}
                                onClick={() => {
                                  this.setState({ filterOffersList: [] });
                                  const updatedCategoryData = {
                                    categoryName:
                                      this.state.oldCategory.categoryName,
                                    categoryId:
                                      this.state.oldCategory.categoryId,
                                  };

                                  this.setState(
                                    {
                                      selectCategory:
                                        this.state.oldCategory.categoryId,
                                      oldCategory: "",
                                      isFilterClear: false,
                                      isViewAllCategoryOffers: false,
                                      categoryData: updatedCategoryData,
                                    },
                                    () => {
                                      this.props.history.push(
                                        `/bank-view/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                      );
                                    }
                                  );
                                  document.querySelector("body").scrollTo(0, 0);
                                }}
                                size={"lg"}
                                buttonIcon={GetIcon("close")}
                              /> */}
                              <FilterCard
                                type={"clear"}
                                isText={true}
                                isActive={true}
                                iconLeft={""}
                                text={"Clear all"}
                                isRadioBtn={true}
                                isRemove={true}
                                onClickClear={() => {
                                  this.setState({ filterOffersList: [] });
                                  const updatedCategoryData = {
                                    categoryName:
                                      this.state.oldCategory.categoryName,
                                    categoryId:
                                      this.state.oldCategory.categoryId,
                                  };

                                  this.setState(
                                    {
                                      selectCategory:
                                        this.state.oldCategory.categoryId,
                                      oldCategory: "",
                                      isFilterClear: false,
                                      isViewAllCategoryOffers: false,
                                      categoryData: updatedCategoryData,
                                    },
                                    () => {
                                      this.props.history.push(
                                        `/credit-card-offers/${bankData?.bankName}/${bankData?.bankId}/${updatedCategoryData.categoryName}/${updatedCategoryData.categoryId}`
                                      );
                                    }
                                  );
                                  document.querySelector("body").scrollTo(0, 0);
                                }}
                                // onClick={() =>
                                //   viewPointWidth < 480 && this.props.onClickMobileFilterOpen()
                                // }
                              />
                            </div>
                          )}
                        </>
                      )}
                      {isLoading && (
                        <div
                          className="rvcw__offer-list"
                          style={{
                            marginTop: "15%",
                            background: "#ffff",
                            padding: "16px",
                            borderRadius: "16px",
                          }}
                        >
                          {/* <div className="d-flex flex-wrap"> */}
                          <OfferCardSmallShimmer />
                          <OfferCardSmallShimmer />
                          <OfferCardSmallShimmer />
                          <OfferCardSmallShimmer />
                          <OfferCardSmallShimmer />
                          <OfferCardSmallShimmer />
                          {/* </div> */}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerData: state.form.offerData,
    // categoryList: state.category.data,
    // bankList: state.bank.bankData,
    insightData: state.insightReducer.insightData,
    isSignUp: state.form.signUp,
    isSignIn: state.form.signIn,
    logInUser: state.user.data,
    isLogin: state.user.isLogin,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickViewMoreOfferDetails: (payload, categoryData, bankData) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
        categoryData: categoryData,
        bankData: bankData,
      }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickOpenSignUp: () => dispatch({ type: actionTypes.OPEN_SIGN_UP }),
    onClickOpenSignIn: () => dispatch({ type: actionTypes.OPEN_SIGN_IN }),
    onClickOpenNewOffer: () => dispatch({ type: actionTypes.CREATE_NEW_OFFER }),
    getLoginUserDetailsById: (id) => dispatch(getLoginUserDetailsById(id)),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    userLogged: () => dispatch({ type: actionTypes.USER_LOGGED }),
    onClickOpenProfileView: (payload) =>
      dispatch({ type: actionTypes.OPEN_PROFILE_VIEW, payload: payload }),
    setFilterOptions: () => dispatch({ type: actionTypes.CLEAR_ALL_FILTER }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewBank)
);
