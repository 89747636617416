import React from "react";
import "./styles.scss";

const RestaurantAdCard = (props) => {
  return (
    <>
      {props.type !== "text" && (
        <>
          <h5 className="ad_title">Sponsored</h5>
          <div className={`ad-card-wrapper ${props.style}`} style={styles.card}>
            {props.isImage && (
              <div className={"ad-ad-image-wrapper"}>
                <img
                  src="https://cco-media-v4.s3.ap-southeast-1.amazonaws.com/66705872bef4a4222ff1fa62.jpg"
                  alt="Restaurant Interior"
                  style={styles.image}
                />
              </div>
            )}
            <div className={"d-flex flex-column"} style={styles.textContainer}>
              <div>
                <h2 className={"ad-card-title"}>{props.title}</h2>
                <p className={"ad-card-description"}>{props.description}</p>

                <a
                  href="http://www.ooge.lk"
                  target="_blank"
                  className={"ad-link"}
                >
                  {props.link}
                </a>
              </div>
            </div>
          </div>
        </>
      )}
      {props.type == "text" && (
        <div
          className={`ad-text-card-wrapper ${props.style}`}
          style={styles.card}
        >
          <h5 className="adt-title">Sponsored</h5>
          <div className={"d-flex flex-column"} style={styles.textContainer}>
            <div>
              <h2 className={"ad-text-card-title"}>{props.title}</h2>
              <p className={"ad-text-card-description"}>{props.description}</p>

              <a
                href="http://www.ooge.lk"
                target="_blank"
                className={"ad-link"}
              >
                {props.link}
              </a>
            </div>

            <div className={"ad-more-btn"}>{props.btnName}</div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = {
  card: {
    //width: '300px',
    // boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
  },
  image: {
    width: "100%",
    //height: '100px',
    objectFit: "conver",
  },
  textContainer: {
    //padding: '15px',
  },
  title: {
    fontSize: "14",
    margin: "0 0 10px 0",
  },
  description: {
    fontSize: "16px",
    margin: "0 0 10px 0",
  },
  link: {
    fontSize: "14px",
    color: "#7d3af2",
    textDecoration: "none",
    display: "block",
    marginBottom: "10px",
  },
  button: {
    padding: "10px 15px",
    fontSize: "16px",
    backgroundColor: "#5a9c31",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
};

export default RestaurantAdCard;
