import React, { Component } from "react";
import "./style.scss";
import "./mobile.scss";
import {
  getMaskedPhoneNumber,
  GetMessageText,
  getToken,
  login,
  StringRegex,
  userDetails,
  validateNumber,
  validateUserPassword,
} from "../../utils";

import * as actionTypes from "../../store/actions/types";
import { openNotification } from "../../config/notification";
import * as user from "../../service/userApi";
import { connect } from "react-redux";
import { GetIcon } from "../../config/icon";
import {
  Button,
  CardNavigation,
  CheckBox,
  FilterColor_1,
  FilterColor_2,
  Input,
  JoinFormHeader,
  OtpResendTimer,
} from "../../components";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCheckLine,
} from "react-icons/ri";

class VerifyMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      attempt: 0,
      termsAndCondition: true,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
      formHeader: "Verify Mobile Number",
      userType: 1,
      isLogo: true,
      timer: "00:00",
      userData: null,
      dataForm: {
        mobileNo: {
          key: "mobileNo",
          elementType: "phoneNo",
          elementConfig: {
            type: "input",
            placeholder: "Ex : 715 986 461",
            minLength: "9",
            maxLength: "9",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 9,
            mobileNo: true,
          },
          valid: false,
          touched: false,
          label: "Enter your Mobile No",
        },
        otpVerification: {
          key: "otpVerification",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0 0 0 0 0 0",
            minLength: "6",
            maxLength: "6",
          },
          value: "",
          validation: {
            required: true,
            minLength: 6,
            maxLength: 6,
          },
          valid: false,
          touched: false,
          label: "Enter 5 digit code here",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  onClickGoToNext = (_wizardNo) => {
    this.setState({
      wizardNo: _wizardNo,
    });
  };

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        this.setState({ isFoundUser: false });
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      case "otpVerification":
        updatedFormElement.value = event.target.value;
        if (event.target.value.length === 6) {
          this.setState({ wizardNo: 4 });
        }
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType, termsAndCondition } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;

    for (let itemKey of Object.keys(df)) {
      if (itemKey === "mobileNo") {
        if (df[itemKey].value === "") {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
            formIsValid = false;
          }
        }
      }
    }
    this.setState({ dataForm: df, formIsValid: formIsValid });

    return formIsValid;
  }

  onClickGoBack = (wizardFormNo, userType) => {
    this.setWizardNavigation(wizardFormNo, userType);
  };

  onClickAgreedTermsAndCondition() {
    this.setState({
      termsAndCondition: !this.state.termsAndCondition,
    });
  }

  fetchMobileNumber = (value) => {
    let newValue = value.replace("+", "");
    return (
      "+" + newValue.replace(/\d{8}$/, "") + newValue.replace(/^\d{8}/, "*****")
    );
  };

  async onOtpEnter(otp) {
    console.log("hue otp entered", otp);
    this.setState({ isEnableLoginIn: true, otp: otp });
  }

  async onOtpResend(userId, mobileNo, token) {
    this.setState({ isLoading: true });
    let res = await user.otpSend(userId, mobileNo, token);
    if (res && res.data && res.data.status) {
      this.setState({ isLoading: false, displayOtpResend: false });
    } else if (!res.data.status && res.data.data.attempt === 4) {
      openNotification({
        title: "Account blocked",
        message: res?.data?.message,
        type: "danger",
        container: "top-center",
      });
      this.props.history.push("/");
    } else {
      return false;
    }
  }

  onCountdownFinish() {
    this.setState({ displayOtpResend: true, isEnableLoginIn: false });
  }

  async sendVerify(userId, OTP) {
    this.setState({ isLoading: true, displayOtpResend: false });
    const token = getToken();
    let res = await user.otpVerify(userId, OTP, token);
    if (res && res.data && res.data.status) {
      console.log("sign in user:::", res.data.data);
      this.setState({ isLoading: false, displayOtpResend: false });
      login(res.data.data.token);
      this.props.onAddLoginUser(res.data.data);
      let user = userDetails();
      this.props.onClickCloseOTPVerify();
      if (user && user.userId) {
        this.props.history.push("/");
      }
    } else {
      this.setState({ isLoading: false, displayOtpResend: false });
      //todo

      openNotification({
        title: "One time password",
        message: "Invalid authentication, Please check again OTP",
        type: "danger",
        container: "top-center",
      });
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if bankIdList or categoryIdList have changed
    if (prevProps.isOTPVerify !== this.props.isOTPVerify) {
      if (this.props.isOTPVerify) {
        this.setState({ wizardNo: 4 });
      } else {
        this.setState({ wizardNo: 1 });
      }
    }
    if (prevProps.loginUserData !== this.props.loginUserData) {
      console.log(this.props.loginUserData);
      let newFormData = this.state.dataForm;
      newFormData["mobileNo"].value = this.props.loginUserData.mobileNo;
      this.setState({
        userData: this.props.loginUserData,
        dataForm: newFormData,
        token: this.props.loginUserToken,
      });
      console.log(this.state.dataForm);
    }
  }

  render() {
    const {
      dataForm,
      isLoading,
      isSuccess,
      viewPointHeight,
      viewPointWidth,
      userData,
      token,
      wizardNo,
      otp,
      isExistingUser,
      userType,
      isFoundUser,
      formHeader,
      isLogo,
      displayOtpResend,
    } = this.state;
    const { loginUserData } = this.props;
    return (
      <>
        {/*<h1>Hi....</h1>*/}
        <div className={"join-form-main-wrapper row justify-content-md-center"}>
          <div className={"join-artworkTop"}>
            <img src={require("../../assets/img/join_artWork_top.svg")} />
          </div>
          <div>
            {isLoading && <div className={"loading-mask"} />}

            <div className={"new_join-form-wrapper"}>
              <JoinFormHeader
                isLogo={true}
                mobileNo={dataForm.mobileNo.value}
                history={this.props.history}
                wizardNo={3}
                header={formHeader}
              />

              <div className={"join-form-action-section"}>
                <div className={"data-form-wrapper flex-row"}>
                  {/*------ wizard-4 Mobile no verification  -----------*/}
                  <div className={"join-form-wizard-3"}>
                    {this.state.wizardNo === 4 && (
                      <div className={"data-form-wrapper flex-row "}>
                        <Input
                          elementConfig={dataForm.otpVerification.elementConfig}
                          elementType={dataForm.otpVerification.elementType}
                          required={
                            dataForm.otpVerification.validation.required
                          }
                          isinvalid={!dataForm.otpVerification.valid}
                          isShowPasswordStrengthMeter={false}
                          invalidReason={dataForm.otpVerification.invalidReason}
                          touched={dataForm.otpVerification.touched}
                          value={dataForm.otpVerification.value}
                          isRightIcon={false}
                          rightIcon={GetIcon("eye")}
                          size={"lg"}
                          margin={"m-t-0"}
                          label={dataForm.otpVerification.label}
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.otpVerification.key
                            )
                          }
                        />

                        <div className={"section-wrapper mt-1"}>
                          <div className={"login-form-OTP-message"}>
                            Code not received ? you can sen it again in
                          </div>
                          <div className={"re-send mt-1"}>
                            <OtpResendTimer
                              numberOfInputs={6}
                              timeInterval={20}
                              displayCountdown={!displayOtpResend}
                              countdownText={"Resend"}
                              onOtpEnter={this.onOtpEnter.bind(this)}
                              onCountdownFinish={this.onCountdownFinish.bind(
                                this
                              )}
                            />
                          </div>
                          {displayOtpResend && (
                            <div
                              className={"re-send link"}
                              onClick={() => {
                                let token = getToken();
                                this.setState({
                                  attempt: ++this.state.attempt,
                                });
                                if (this.state.attempt <= 3) {
                                  this.onOtpResend(
                                    this.state.userData.userId,
                                    dataForm.mobileNo.value,
                                    token
                                  );
                                }
                              }}
                            >
                              Resend
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {wizardNo === 4 && (
                  <div className={"join-form-cta-section"}>
                    <div className={"mr-2"}>
                      <Button
                        text={"Done"}
                        type={"dark md"}
                        size={"full-width"}
                        isLoading={isLoading}
                        leftIcon={false}
                        rightIcon={true}
                        rightIconType={"dark"}
                        reactIcon={<RiCheckLine />}
                        onClick={() => {
                          if (userData && userData.userId) {
                            this.sendVerify(
                              userData?.userId,
                              dataForm["otpVerification"].value
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={"login-url-wrap d-flex"}>
              Already have an account ?{" "}
              <div
                className={"link"}
                onClick={() => {
                  this.props.history.push("/sign-in");
                  this.props.onClickCloseOTPVerify();
                }}
              >
                &nbsp; Sign In{" "}
              </div>
            </div>
          </div>
        </div>
        <div className={"filter-bg-1"}>
          <FilterColor_1 />
        </div>
        <div className={"filter-bg-2"}>
          <FilterColor_2 />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUserData: state.user.user,
    loginUserToken: state.user.token,
    isLogin: state.user.isLogin,
    isRegister: state.user.isRegister,
    isOTPVerify: state.user.isOTPVerify,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserDetailsById: (id) => dispatch(user.getLoginUserDetailsById(id)),
    onClickOpenSignUp: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_UP, status: true }),
    onClickCloseSignUp: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_UP, status: false }),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    onClickCloseSignIn: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_IN, status: false }),
    onClickOpenOTPVerify: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    setUserData: (payload) =>
      dispatch({ type: actionTypes.SET_USER_DATA, user: payload }),
    onClickCloseOTPVerify: () =>
      dispatch({ type: actionTypes.CLOSE_OTP_VERIFY }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMobile);
