import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMPRESSION_BASE_URL = process.env.REACT_APP_IMPRESSION_BASE_URL;

export const getBank = async () => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/banks`, options);
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_BANK_API:", err);
    throw err;
  }
};

export const getBankImpressionCount = async (bankId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${IMPRESSION_BASE_URL}/bank/impression/${bankId}`
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_BANK_IMPRESSION_API:", err);
    throw err;
  }
};

export const getBankById = async (bankId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/bank/by/${bankId}`);
    return response.data;
  } catch (err) {
    console.error("ERR_GET_BANK_API:", err);
    throw err;
  }
};

export const getTopOffersByBank = async (insightId, bankId, limit) => {
  try {
    const options = { data: {} };
    // Get response
    // let response = await axios.get(
    //   `${IMPRESSION_BASE_URL}/top-offers-by-bank-insight/${insightId}/${bankId}/${limit}`
    // );
    let response = await axios.get(
      `${IMPRESSION_BASE_URL}/top-offers-by-bank-insight?insight_id=${insightId}&bank_id=${bankId}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.error("ERR_GET_OFFERS_BY_BANK_INSIGHT_API:", err);
    throw err;
  }
};
