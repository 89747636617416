import React from "react";
import "./style.scss";
import {
  RiFacebookFill,
  RiInstagramLine,
  RiPinterestFill,
  RiTiktokFill,
  RiYoutubeFill,
} from "react-icons/ri";

const Index = () => (
  <>
    <div className="cco_footer2-wrapper">
      <div className="f2w_body-wrapper">
        <div className="f2w_bw-title">Following use on social media</div>
        <div className="f2w_bw_content">
          Call out team monday to friday 8.00am to 5.00 pm
        </div>
        <div
          className={
            "f2w_bw-logo-wrapper d-flex flex-row justify-content-center"
          }
          style={{ gap: "11px", padding: "10px" }}
        >
          <div className="f2w_bw-logo">
            <a
              href="https://www.instagram.com/creditcardoffers.lk/"
              target="_blank"
              className="f2w_bw__l-icon"
            >
              <RiInstagramLine />
            </a>
          </div>
          <div className="f2w_bw-logo">
            <a
              href="https://www.youtube.com/@creditcardofferslk"
              target="_blank"
              className="f2w_bw__l-icon"
            >
              <RiYoutubeFill />
            </a>
          </div>
          <div className="f2w_bw-logo">
            <a
              href="https://www.tiktok.com/@creditcardoffers.lk?is_from_webapp=1&sender_device=pc"
              target="_blank"
              className="f2w_bw__l-icon"
            >
              <RiTiktokFill />
            </a>
          </div>
          <div className="f2w_bw-logo">
            <a
              href="https://www.facebook.com/profile.php?id=61559636450006"
              target="_blank"
              className="f2w_bw__l-icon"
            >
              <RiFacebookFill />
            </a>
          </div>
        </div>
      </div>
      <div className="cco_f2w_bottom-wrapper d-flex justify-content-center">
        <div
          className={"d-flex flex-row align-items-center"}
          style={{ padding: "10px" }}
        >
          <span className={"f2w_bw__year"}>2024</span>
          <span className={"f2w_bw__mail"}>creditcardoffers</span>
          <span className={"f2w_bw__lk"}>.lk</span>
          <span className={"f2w_bw__year"}>- All rights reserved</span>
        </div>
      </div>
    </div>
  </>
);
export default Index;
