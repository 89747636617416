import React, { Component } from "react";
import "./style.scss";
import "./mobile.scss";
import {
  getMaskedPhoneNumber,
  GetMessageText,
  getToken,
  login,
  StringRegex,
  userDetails,
  validateNumber,
  validateUserPassword,
} from "../../utils";

import * as actionTypes from "../../store/actions/types";
import { openNotification } from "../../config/notification";
import * as user from "../../service/userApi";
import { connect } from "react-redux";
import { GetIcon } from "../../config/icon";
import {
  Button,
  CardNavigation,
  FilterColor_1,
  FilterColor_2,
  Input,
  JoinFormHeader,
  Navigator,
  OtpResendTimer,
} from "../../components";
import {
  RiArrowLeftLine,
  RiArrowRightLine,
  RiArrowRightSLine,
  RiCheckLine,
} from "react-icons/ri";
import { Helmet } from "react-helmet";

class SignInComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userId: null,
      tags: [],
      tag: null,
      otp: null,
      token: null,
      attempt: 0,
      termsAndCondition: true,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      isEnableLoginIn: false,
      isExistingUser: false,
      isFoundUser: false,
      formHeader: "Sign up",
      userType: 1,
      isLogo: true,
      timer: "00:00",
      userData: null,
      dataForm: {
        firstName: {
          key: "firstName",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "Ex : damith",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "First Name",
        },
        mobileNo: {
          key: "mobileNo",
          elementType: "phoneNo",
          elementConfig: {
            type: "input",
            placeholder: "Ex : 715 986 461",
            minLength: "9",
            maxLength: "9",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 9,
            mobileNo: true,
          },
          valid: false,
          touched: false,
          label: "Enter your Mobile No",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*************",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
        confirmPassword: {
          key: "confirmPassword",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "Confirm password",
          },
          value: "",
          validation: {
            required: true,
            minLength: 5,
            confirmPassword: true,
          },
          valid: false,
          touched: false,
          label: "Confirm Password",
        },
        otpVerification: {
          key: "otpVerification",
          elementType: "input",
          elementConfig: {
            type: "text",
            placeholder: "0 0 0 0 0 0",
            minLength: "6",
            maxLength: "6",
          },
          value: "",
          validation: {
            required: true,
            minLength: 6,
            maxLength: 6,
          },
          valid: false,
          touched: false,
          label: "Enter 5 digit code here",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  onClickGoToNext = (_wizardNo) => {
    this.setState({
      wizardNo: _wizardNo,
    });
  };

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        this.setState({ isFoundUser: false });
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      case "otpVerification":
        updatedFormElement.value = event.target.value;
        if (event.target.value.length === 6) {
          this.setState({ wizardNo: 4 });
          // alert("event fire...");
        }
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }
    if (rules.mobileNo) {
      isValid = true;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType, termsAndCondition } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    //========= VALIDATION USER -- OFFER VIEWER
    if (wizardNo === 2 && userType === 1) {
      for (let itemKey of Object.keys(df)) {
        if (itemKey === "firstName") {
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          } else {
            if (!StringRegex.test(df[itemKey].value)) {
              df[itemKey].touched = true;
              df[itemKey].valid = false;
              df[itemKey].invalidReason = GetMessageText("invalidFirstName");
              formIsValid = false;
            }
          }
        }
        if (itemKey === "mobileNo") {
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          } else {
            if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
              df[itemKey].touched = true;
              df[itemKey].valid = false;
              df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
              formIsValid = false;
            }
          }
        }
      }
      this.setState({ dataForm: df, formIsValid: formIsValid });
    }

    if (wizardNo === 3 && userType === 1) {
      for (let itemKey of Object.keys(df)) {
        if (itemKey === "password") {
          if (!validateUserPassword(df[itemKey].value)) {
            df[itemKey].touched = true;
            formIsValid = false;
          }
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          }
        }
        if (itemKey === "confirmPassword") {
          if (df[itemKey].value === "") {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            formIsValid = false;
          }
        }
        if (df.password.value !== df.confirmPassword.value) {
          df[itemKey].invalidReason = GetMessageText("passwordNotMatch");
          formIsValid = false;
        }

        if (!this.state.termsAndCondition) {
          formIsValid = false;
        }
      }
      this.setState({ dataForm: df, formIsValid: formIsValid });
    }

    return formIsValid;
  }

  onClickRightIcon(key) {
    let df = { ...this.state.dataForm };
    if (df[key].elementConfig.type === "password")
      df[key].elementConfig.type = "text";
    else df[key].elementConfig.type = "password";
    this.setState({ dataForm: df });
  }

  onClickGoBack = (wizardFormNo, userType) => {
    this.setState({ wizardNo: wizardFormNo, userType });
    // this.setWizardNavigation(wizardFormNo, userType);
  };

  onClickAgreedTermsAndCondition() {
    this.setState({
      termsAndCondition: !this.state.termsAndCondition,
    });
  }

  fetchMobileNumber = (value) => {
    let newValue = value.replace("+", "");
    return (
      "+" + newValue.replace(/\d{8}$/, "") + newValue.replace(/^\d{8}/, "*****")
    );
  };

  async onOtpEnter(otp) {
    console.log("hue otp entered", otp);
    this.setState({ isEnableLoginIn: true, otp: otp });
  }

  async onOtpResend(userId, mobileNo, token) {
    this.setState({ isLoading: true });
    let res = await user.otpSend(userId, mobileNo, token);
    if (res && res.data && res.data.status) {
      this.setState({ isLoading: false, displayOtpResend: false });
    } else if (!res.data.status && res.data.data.attempt === 4) {
      openNotification({
        title: "Account blocked",
        message: res?.data?.message,
        type: "danger",
        container: "top-center",
      });
      this.props.history.push("/");
    } else {
      return false;
    }
  }

  onCountdownFinish() {
    this.setState({ displayOtpResend: true, isEnableLoginIn: false });
  }

  async sendVerify(userId, OTP) {
    this.setState({ isLoading: true, displayOtpResend: false });
    const token = getToken();
    let res = await user.otpVerify(userId, OTP, token);
    console.log("otpVerify:::", res);
    if (res && res.data && res.data.status) {
      console.log("sign in user:::", res.data.data);
      this.setState({ isLoading: false, displayOtpResend: false });
      login(res.data.data.token);
      this.props.onAddLoginUser(res.data.data);
      let user = userDetails();
      this.props.onClickCloseOTPVerify();
      if (user && user.userId) {
        this.props.history.push("/");
      }
    } else {
      this.setState({ isLoading: false, displayOtpResend: false });
      //todo

      openNotification({
        title: "One time password",
        message: "Invalid authentication, Please check again OTP",
        type: "danger",
        container: "top-center",
      });
    }
  }

  async saveUser() {
    let { userType, termsAndCondition } = this.state;
    this.setState({ isLoading: true });
    let df = { ...this.state.dataForm };
    let res = await user.createUser(
      userType,
      null,
      null,
      df.mobileNo.value,
      df.firstName.value,
      df.password.value,
      termsAndCondition
    );
    console.log("user", res);
    if (res && res.data && res.data.status) {
      login(res.data.data.token);
      // this.props.onClickCloseSignUp();
      this.props.onAddLoginUser(res.data.data.user);
      this.setState({
        isLoading: false,
        wizardNo: 4,
        userData: res.data.data.user,
        formHeader: "Verify you mobile no",
      });
      this.props.setUserData(res.data.data.user);
    } else {
      this.setState({ isLoading: false });
    }
  }

  // async sendVerify(userId, OTP, token) {
  //   this.setState({ isLoading: true, displayOtpResend: false });
  //   let res = await user.otpVerify(userId, OTP, token);
  //   if (res && res.data && res.data.status) {
  //     console.log("sign in user:::", res.data.data);
  //     this.setState({ isLoading: false, displayOtpResend: false });
  //     login(res.data.token);
  //     this.props.onAddLoginUser(res.data.data);
  //     this.props.onClickCloseSignUp();
  //   } else {
  //     this.setState({ isLoading: false, displayOtpResend: false });
  //     openNotification({
  //       title: "One time password",
  //       message: "Invalid authentication, Please check again OTP",
  //       type: "danger",
  //       container: "top-center",
  //     });
  //   }
  // }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userMobileNo !== this.props.userMobileNo) {
      console.log(this.props.userMobileNo);
      if (this.props.userMobileNo) {
        let newFormData = this.state.dataForm;
        newFormData["mobileNo"].value = this.props.userMobileNo;
        this.setState({
          wizardNo: 2,
          dataForm: newFormData,
        });
      }
    }
    if (prevProps.isOTPVerify !== this.props.isOTPVerify) {
      if (this.props.isOTPVerify) {
        this.setState({ wizardNo: 4 });
      } else {
        this.setState({ wizardNo: 1 });
      }
    }
    if (prevProps.loginUserData !== this.props.loginUserData) {
      console.log(this.props.loginUserData);
      let newFormData = this.state.dataForm;
      newFormData["mobileNo"].value = this.props.loginUserData.mobileNo;
      this.setState({
        userData: this.props.loginUserData,
        dataForm: newFormData,
        token: this.props.loginUserToken,
      });
      console.log(this.state.dataForm);
    }
  }

  render() {
    const {
      dataForm,
      isLoading,
      isSuccess,
      viewPointHeight,
      viewPointWidth,
      userData,
      token,
      wizardNo,
      otp,
      isExistingUser,
      userType,
      isFoundUser,
      formHeader,
      isLogo,
      displayOtpResend,
    } = this.state;
    const { loginUserData } = this.props;
    return (
      <>
        {/*<h1>Hi....</h1>*/}
        <Helmet>
          <title>
            Sign Up - Join to Access Best Credit Card Offers in Sri Lanka
          </title>
          <meta
            name="description"
            content="Sign up for CreditCardOffers.lk and start saving with exclusive credit card deals, promotions, and discounts in Sri Lanka. Join now to access the best offers."
          />
          <link
            rel="canonical"
            href="https://www.creditcardoffers.lk/sign-up"
          />
        </Helmet>

        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "WebPage",
                "name": "Sign Up - CreditCardOffers.lk",
                "url": "https://www.creditcardoffers.lk/sign-up",
                "description": "Sign up to CreditCardOffers.lk to access exclusive credit card offers, deals, and promotions in Sri Lanka.",
                "mainEntityOfPage": "https://www.creditcardoffers.lk/sign-up"
              }
            `}
          </script>
        </Helmet>

        <div className={"join-form-main-wrapper justify-content-md-center"}>
          <div className={"join-artworkTop"}>
            <img src={require("../../assets/img/join_artWork_top.svg")} />
          </div>
          <div className={"mobile-join-artworkTop"}>
            <img
              src={require("../../assets/img/mobile_join_artWork_top.svg")}
            />
          </div>
          <div>
            {isLoading && <div className={"loading-mask"} />}

            <div className={"new_join-form-wrapper"}>
              <div
                className={"d-flex justify-content-start "}
                style={{ marginBottom: "12px" }}
              >
                <Navigator
                  size={"sm"}
                  icon={"leftArrow"}
                  navigationTitle={"Home"}
                  onClick={async () => this.props.history.push("/")}
                />
              </div>
              <JoinFormHeader
                isLogo={true}
                mobileNo={
                  loginUserData && loginUserData?.mobileNo
                    ? loginUserData?.mobileNo
                    : dataForm.mobileNo.value
                }
                history={this.props.history}
                wizardNo={wizardNo === 3 ? 4 : wizardNo === 4 ? 3 : wizardNo}
                header={formHeader}
              />

              <div className={"join-form-action-section"}>
                <div className={"data-form-wrapper flex-row"}>
                  {/*wizard-1*/}
                  <div className={"form-inner-wrapper flex-column"}>
                    {this.state.wizardNo === 1 && (
                      <>
                        <div className={"data-form-wrapper flex-row "}>
                          <div className={"join-form-page-title mb-3"}>
                            Tell us about your purpose
                          </div>
                          <div className={"mb-3"}>
                            <CardNavigation
                              imgTag={
                                <img
                                  className={"lading-bg"}
                                  width={"42"}
                                  height={"42"}
                                  src={require("../../assets/img/Card/navigation/viwer.svg")}
                                  alt="all offers in one place"
                                />
                              }
                              size={"lg"}
                              icon={<RiCheckLine />}
                              isState={true}
                              // onClick={() => this.onClickGoToNext(2, 1)}
                              text={"I want to see offers"}
                            />
                          </div>
                          <div>
                            <CardNavigation
                              imgTag={
                                <img
                                  className={"lading-bg"}
                                  width={"42"}
                                  height={"42"}
                                  src={require("../../assets/img/Card/navigation/publisher.svg")}
                                  alt="all offers in one place"
                                />
                              }
                              size={"lg"}
                              disabled={true}
                              isState={this.state.userType === 2}
                              icon={<RiArrowRightSLine />}
                              onClick={() => alert("invalid")}
                              text={"I want to publish offers"}
                              title={"coming soon.."}
                            />
                          </div>
                        </div>
                        <div
                          className={"mobile-login-url-wrap d-flex flex-row "}
                        >
                          Already have an account ?{" "}
                          <div
                            className={"link"}
                            onClick={() => {
                              this.props.history.push("/sign-in");
                              this.props.onClickCloseOTPVerify();
                            }}
                          >
                            &nbsp; Sign In{" "}
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {/*------ wizard-2   -----------*/}
                  <div className={"join-form-wizard-2"}>
                    {wizardNo === 2 && (
                      <>
                        <Input
                          elementConfig={dataForm.firstName.elementConfig}
                          elementType={dataForm.firstName.elementType}
                          required={dataForm.firstName.validation.required}
                          isinvalid={!dataForm.firstName.valid}
                          isShowPasswordStrengthMeter={false}
                          invalidReason={dataForm.firstName.invalidReason}
                          touched={dataForm.firstName.touched}
                          value={dataForm.firstName.value}
                          isRightIcon={false}
                          rightIcon={GetIcon("eye")}
                          size={"lg"}
                          margin={"m-t-0"}
                          label={dataForm.firstName.label}
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.firstName.key
                            )
                          }
                        />
                        <Input
                          elementConfig={dataForm.mobileNo.elementConfig}
                          elementType={dataForm.mobileNo.elementType}
                          required={dataForm.mobileNo.validation.required}
                          maxLength={dataForm.mobileNo.validation.maxLength}
                          invalidReason={dataForm.mobileNo.invalidReason}
                          isinvalid={!dataForm.mobileNo.valid}
                          touched={dataForm.mobileNo.touched}
                          value={dataForm.mobileNo.value}
                          size={"lg"}
                          verificationStatus={dataForm.mobileNo.valid}
                          showVerification={!!dataForm.mobileNo.value}
                          margin={"m-t-24"}
                          label={dataForm.mobileNo.label}
                          changed={(event) =>
                            this.inputHandleChange(event, dataForm.mobileNo.key)
                          }
                        />
                        <div
                          className={"mobile-login-url-wrap d-flex flex-row "}
                        >
                          Already have an account ?{" "}
                          <div
                            className={"link"}
                            onClick={() => {
                              this.props.history.push("/sign-in");
                              this.props.onClickCloseOTPVerify();
                            }}
                          >
                            &nbsp; Sign In{" "}
                          </div>
                        </div>
                      </>
                    )}
                    {isFoundUser && (
                      <div className={"mobile-no-found"}>
                        {getMaskedPhoneNumber(dataForm.mobileNo.value)} This
                        mobile number is already registered. Would you like to
                        log in with it?{" "}
                        <span
                          className={"mnf__sign-in"}
                          onClick={() => {
                            this.props.history.push("/sign-in");
                          }}
                        >
                          sign-in
                        </span>
                      </div>
                    )}
                  </div>

                  {/*------ wizard-3 set password -----------*/}
                  <div className={"join-form-wizard-3"}>
                    {this.state.wizardNo === 3 && (
                      <div className={"data-form-wrapper flex-row "}>
                        <Input
                          elementConfig={dataForm.password.elementConfig}
                          elementType={dataForm.password.elementType}
                          required={dataForm.password.validation.required}
                          isinvalid={!dataForm.password.valid}
                          isShowPasswordStrengthMeter={true}
                          invalidReason={dataForm.password.invalidReason}
                          touched={dataForm.password.touched}
                          value={dataForm.password.value}
                          isRightIcon={true}
                          rightIcon={GetIcon("eye")}
                          onClickRightIcon={() =>
                            this.onClickRightIcon(dataForm.password.key)
                          }
                          size={"lg"}
                          margin={"m-t-0"}
                          label={dataForm.password.label}
                          changed={(event) =>
                            this.inputHandleChange(event, dataForm.password.key)
                          }
                        />
                        <Input
                          elementConfig={dataForm.confirmPassword.elementConfig}
                          elementType={dataForm.confirmPassword.elementType}
                          required={
                            dataForm.confirmPassword.validation.required
                          }
                          isinvalid={!dataForm.confirmPassword.valid}
                          isShowPasswordStrengthMeter={false}
                          invalidReason={dataForm.confirmPassword.invalidReason}
                          touched={dataForm.confirmPassword.touched}
                          value={dataForm.confirmPassword.value}
                          isRightIcon={false}
                          rightIcon={GetIcon("eye")}
                          size={"lg"}
                          margin={"m-t-24"}
                          label={dataForm.confirmPassword.label}
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.confirmPassword.key
                            )
                          }
                        />
                        {/* <>
                          <div className={"d-flex flex-start mt-3"}>
                            <CheckBox
                              onClick={() =>
                                this.onClickAgreedTermsAndCondition()
                              }
                              isState={this.state.termsAndCondition}
                              icon={<RiCheckLine />}
                              link={"terms and conditions"}
                              text={"I agree to the website"}
                            />
                          </div>
                        </> */}
                      </div>
                    )}
                  </div>

                  {/*------ wizard-4 Mobile no verification  -----------*/}
                  <div className={"join-form-wizard-3"}>
                    {this.state.wizardNo === 4 && (
                      <div className={"data-form-wrapper flex-row "}>
                        <Input
                          elementConfig={dataForm.otpVerification.elementConfig}
                          elementType={dataForm.otpVerification.elementType}
                          required={
                            dataForm.otpVerification.validation.required
                          }
                          isinvalid={!dataForm.otpVerification.valid}
                          isShowPasswordStrengthMeter={false}
                          invalidReason={dataForm.otpVerification.invalidReason}
                          touched={dataForm.otpVerification.touched}
                          value={dataForm.otpVerification.value}
                          isRightIcon={false}
                          rightIcon={GetIcon("eye")}
                          size={"lg"}
                          margin={"m-t-0"}
                          label={dataForm.otpVerification.label}
                          changed={(event) =>
                            this.inputHandleChange(
                              event,
                              dataForm.otpVerification.key
                            )
                          }
                        />

                        <div className={"section-wrapper mt-1"}>
                          <div className={"login-form-OTP-message"}>
                            Code not received ? you can sen it again in
                          </div>
                          <div className={"re-send mt-1"}>
                            <OtpResendTimer
                              numberOfInputs={6}
                              timeInterval={20}
                              displayCountdown={!displayOtpResend}
                              countdownText={"Resend"}
                              onOtpEnter={this.onOtpEnter.bind(this)}
                              onCountdownFinish={this.onCountdownFinish.bind(
                                this
                              )}
                            />
                          </div>
                          {displayOtpResend && (
                            <div
                              className={"re-send link"}
                              onClick={() => {
                                let token = getToken();
                                this.setState({
                                  attempt: ++this.state.attempt,
                                });
                                if (this.state.attempt <= 3) {
                                  this.onOtpResend(
                                    this.state.userData.userId,
                                    dataForm.mobileNo.value,
                                    token
                                  );
                                }
                              }}
                            >
                              Resend
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {wizardNo <= 3 && (
                  <div
                    className={
                      "join-form-cta-section d-flex justify-content-center"
                    }
                  >
                    {wizardNo > 1 && (
                      <div className={"mr-2"}>
                        <Button
                          text={"Back"}
                          type={"back md "}
                          size={"full-width"}
                          isLoading={false}
                          leftIcon={true}
                          rightIcon={false}
                          rightIconType={"back"}
                          reactIcon={<RiArrowLeftLine />}
                          onClick={() =>
                            this.onClickGoBack(
                              wizardNo !== 1 ? wizardNo - 1 : "1",
                              userType
                            )
                          }
                        />
                      </div>
                    )}
                    <Button
                      text={!isExistingUser ? "Next" : "Sign up"}
                      type={"dark md"}
                      size={"full-width"}
                      isLoading={isLoading}
                      rightIcon={true}
                      rightIconType={"dark"}
                      reactIcon={<RiArrowRightLine />}
                      onClick={async () => {
                        if (wizardNo === 1) {
                          this.setState({ wizardNo: 2 });
                        } else if (wizardNo === 2) {
                          let validationStatus =
                            await this.updateInputValidationErrors();
                          console.log("validationStatus", validationStatus);
                          if (validationStatus) {
                            //check mobile-no is exising
                            this.setState({ isLoading: true });
                            let existingMobileRes =
                              await user.isExistingUserMobileNo(
                                dataForm["mobileNo"].value
                              );
                            if (existingMobileRes && existingMobileRes.status) {
                              this.setState({ isLoading: false });
                              console.log(
                                "existingMobileRes",
                                existingMobileRes
                              );
                              this.setState({ isFoundUser: true });
                            } else {
                              this.setState({
                                isLoading: false,
                                isLogo: false,
                                wizardNo: 3,
                                isFoundUser: false,
                              });
                            }
                          }
                        } else if (wizardNo === 3) {
                          let validationStatus =
                            await this.updateInputValidationErrors();
                          if (validationStatus) {
                            this.saveUser();
                          }
                        }
                      }}
                    />
                  </div>
                )}

                {wizardNo === 4 && (
                  <div className={"join-form-cta-section"}>
                    <div className={"mr-2"}>
                      <Button
                        text={"Done"}
                        type={"dark md"}
                        size={"full-width"}
                        rightIconType={"dark"}
                        isLoading={isLoading}
                        leftIcon={false}
                        rightIcon={true}
                        reactIcon={<RiCheckLine />}
                        onClick={async () => {
                          if (userData && userData.userId) {
                            await this.sendVerify(
                              userData?.userId,
                              dataForm["otpVerification"].value
                            );
                          }
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className={"login-url-wrap d-flex"}>
              Already have an account ?{" "}
              <div
                className={"link"}
                onClick={() => {
                  this.props.history.push("/sign-in");
                  this.props.onClickCloseOTPVerify();
                }}
              >
                &nbsp; Sign In{" "}
              </div>
            </div>
          </div>
          {wizardNo <= 3 && (
            <div
              className={
                "mobile-join-form-cta-section d-flex flex-ow justify-content-center"
              }
            >
              {wizardNo > 1 && (
                <div className={"mr-2"}>
                  <Button
                    text={"Back"}
                    type={"back md "}
                    size={"full-width"}
                    rightIconType={"back"}
                    isLoading={false}
                    leftIcon={true}
                    rightIcon={false}
                    reactIcon={<RiArrowLeftLine />}
                    onClick={() =>
                      this.onClickGoBack(
                        wizardNo !== 1 ? wizardNo - 1 : "1",
                        userType
                      )
                    }
                  />
                </div>
              )}
              <Button
                text={!isExistingUser ? "Next" : "Sign up"}
                type={"dark md"}
                size={"full-width"}
                isLoading={isLoading}
                rightIcon={true}
                rightIconType={"dark"}
                reactIcon={<RiArrowRightLine />}
                onClick={async () => {
                  if (wizardNo === 1) {
                    this.setState({ wizardNo: 2 });
                  } else if (wizardNo === 2) {
                    let validationStatus =
                      await this.updateInputValidationErrors();
                    console.log("validationStatus", validationStatus);
                    if (validationStatus) {
                      //check mobile-no is exising
                      this.setState({ isLoading: true });
                      let existingMobileRes = await user.isExistingUserMobileNo(
                        dataForm["mobileNo"].value
                      );
                      if (existingMobileRes && existingMobileRes.status) {
                        this.setState({ isLoading: false });
                        console.log("existingMobileRes", existingMobileRes);
                        this.setState({ isFoundUser: true });
                      } else {
                        this.setState({
                          formHeader: "Sign up",
                          isLoading: false,
                          isLogo: false,
                          wizardNo: 3,
                          isFoundUser: false,
                        });
                      }
                    }
                  } else if (wizardNo === 3) {
                    let validationStatus =
                      await this.updateInputValidationErrors();
                    if (validationStatus) {
                      this.saveUser();
                    }
                  }
                }}
              />
            </div>
          )}

          {wizardNo === 4 && (
            <div className={"mobile-done-join-form-cta-section"}>
              <div className={"mr-2"}>
                <Button
                  text={"Done"}
                  type={"dark md"}
                  size={"full-width"}
                  isLoading={isLoading}
                  leftIcon={false}
                  rightIcon={true}
                  rightIconType={"dark"}
                  reactIcon={<RiCheckLine />}
                  onClick={async () => {
                    if (userData && userData.userId) {
                      await this.sendVerify(
                        userData?.userId,
                        dataForm["otpVerification"].value
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className={"filter-bg-1"}>
          <FilterColor_1 />
        </div>
        <div className={"filter-bg-2"}>
          <FilterColor_2 />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    loginUserData: state.user.user,
    loginUserToken: state.user.token,
    isLogin: state.user.isLogin,
    isRegister: state.user.isRegister,
    isOTPVerify: state.user.isOTPVerify,
    userMobileNo: state.user.userMobileNo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserDetailsById: (id) => dispatch(user.getLoginUserDetailsById(id)),
    onClickOpenSignUp: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_UP, status: true }),
    onClickCloseSignUp: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_UP, status: false }),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    onClickCloseSignIn: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_IN, status: false }),
    onClickOpenOTPVerify: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    setUserData: (payload) =>
      dispatch({ type: actionTypes.SET_USER_DATA, user: payload }),
    onClickCloseOTPVerify: () =>
      dispatch({ type: actionTypes.CLOSE_OTP_VERIFY }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInComponent);
