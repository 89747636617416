import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMPRESSION_BASE_URL = process.env.REACT_APP_IMPRESSION_BASE_URL;

export const getOffer = async () => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(`${BASE_URL}/get/all/offers`, options);
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};
export const getOfferByPage = async (pageNo, pageSize) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/page/` + pageNo + `/` + pageSize,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByBankId = async (bankId, pageNo, pageSize) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/bank/` +
        bankId +
        `/` +
        pageNo +
        `/` +
        pageSize,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_FILTER_BY_BANK_API:", err);
    throw err;
  }
};

export const getOfferByCategoryId = async (categoryId, pageNo, pageSize) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/category/` +
        categoryId +
        `/` +
        pageNo +
        `/` +
        pageSize,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_FILTER_BY_CATEGORY_API:", err);
    throw err;
  }
};

export const getOfferByCategoryByBank = async (
  bankId,
  categoryId,
  pageNo,
  pageSize
) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/filter/by/` +
        bankId +
        `/` +
        categoryId +
        `/` +
        pageNo +
        `/` +
        pageSize,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_FILTER_BY_CATEGORY_BANK_API:", err);
    throw err;
  }
};

export const getOfferByCategoriesBanksMultiple = async (
  body,
  pageNo,
  pageSize
) => {
  try {
    // Get response
    let response = await axios.post(
      `${BASE_URL}/get/offers/filter/multiple/${pageNo}/${pageSize}`,
      body
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_FILTER_BY_CATEGORY_BANK_API:", err);
    throw err;
  }
};

export const getOfferByOfferId = async (offerId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offer/by/${offerId}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_OFFER_BY_ID_API:", err);
    throw err;
  }
};

export const getOfferByUserId = async (userId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/user/${userId}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOffersByBank = async (bankId) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offers/by/bank/${bankId}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferInsights = async (
  merchantId,
  bankId,
  categoryId,
  size
) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offer/insights/${merchantId}/${bankId}/${categoryId}/${size}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferCount = async (status) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.get(
      `${BASE_URL}/get/offer/count/${status}`,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferCountMultiple = async (status, body) => {
  try {
    const options = { data: {} };
    // Get response
    let response = await axios.post(
      `${BASE_URL}/get/offer/count/multiple/${status}`,
      body,
      options
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_OFFER_API:", err);
    throw err;
  }
};

export const getOfferByIdMultiple = async (body) => {
  try {
    // Get response
    let response = await axios.post(`${BASE_URL}/get/offer/multiple`, body);
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_CATEGORY_API:", err);
    throw err;
  }
};

export const getOfferByBanIdCategoryMultiple = async (body) => {
  try {
    // Get response
    let response = await axios.post(
      `${BASE_URL}/get/offer/bank/category/multiple`,
      body
    );
    return response.data;
  } catch (err) {
    console.error("ERR_GET_ALL_CATEGORY_API:", err);
    throw err;
  }
};

export const getTopOffersByInsightId = async (insightId, limit) => {
  try {
    const options = { data: {} };
    let response = await axios.get(
      `${IMPRESSION_BASE_URL}/top-offers-by-insight?insight_id=${insightId}&limit=${limit}`
    );
    return response;
  } catch (err) {
    console.error("ERR_GET_OFFERS_BY_BANK_INSIGHT_API:", err);
    throw err;
  }
};

// export const getTopOffersByInsightId = async (insightId) => {
//   try {
//     const options = { data: {} };
//     let response = await axios.get(
//       `${IMPRESSION_BASE_URL}/get/offers/${insightId}/top-10-most-viewed`
//     );
//     return response;
//   } catch (err) {
//     console.error("ERR_GET_OFFERS_BY_BANK_INSIGHT_API:", err);
//     throw err;
//   }
// };
