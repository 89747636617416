import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div
      className={`filter-color-third-wrapper d-flex flex-column align-items-center`}
    >
      <div className={`fctw__color1`} />

      <div className={`fctw__color2`} />
      <div className={`fctw__color3`} />
    </div>
  </>
);

Index.propTypes = {};

Index.defaultProps = {
  text: "",
  icon: "",
};

export default Index;
