import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { BiX } from "react-icons/bi";
import { GetIcon } from "../../../config/icon";

const Index = (props) => (
  <div
    key={props.key}
    className={`recent-tag-wrapper ${props.type}  ${
      props.isActive ? "active" : ""
    }`}
    onClick={props.onClick}
  >
    <div className={"rt__icon"}>{GetIcon(props.iconName)}</div>
    <div className={"rt__title"}>{props.title}</div>
  </div>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
