import React, { useEffect, useState } from "react";
import "./styles.scss";
import "./mobile.scss";
import { getInsight } from "../../../utils";
import {
  getOfferByIdMultiple,
  getRecentViewOfferIDByInsight,
  getTopOffersByInsightId,
} from "../../../service";
import {
  Button,
  ButtonIcon,
  OfferCardSmall,
  OfferFavoriteCard,
  SectionHeader,
} from "../../../components";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import Slider from "react-slick";
import { GetIcon } from "../../../config/icon";

const Index = (props) => {
  const [topOffersList, setTopOffersList] = useState([]);
  const [isCollapse, setIsCollapse] = useState(false);
  useEffect(() => {
    const fetchInsight = async () => {
      try {
        const data = await getInsight();
        console.log("bankSelctCard:", data);
        if (data?.insightId) {
          //   let idList = await getRecentViewOfferIDByInsight(data?.insightId);
          //   console.log({ idList });
          //   let topOffersList = await getOfferByIdMultiple({
          //     offerIds: idList,
          //   });
          const response = await getTopOffersByInsightId(
            data?.insightId,
            // "6707a9627aa9969e3e0120a9",
            10
          );

          console.log({ response });
          if (response.data.top_offers?.length > 0) {
            console.log("topOffers", response?.data?.top_offers);
            let topOffersList = await getOfferByIdMultiple({
              offerIds: response?.data?.top_offers,
            });
            console.log({ topOffersList });
            if (topOffersList?.data?.length > 0) {
              const newList = topOffersList.data.map((element, index) => ({
                ...element,
                index,
              }));

              const sortedData = [...newList].reverse(); // Reverse based on original order (descending index)
              console.log({ sortedData });
              setTopOffersList(sortedData);
            }
          }
        }
      } catch (err) {
        console.error(err);
      } finally {
      }
    };
    fetchInsight();
    //     const insight = await getInsight();
    // if (insight?.insightId) {
    //   try {
    //     const response = await getTopOffersByInsightId(
    //       // insight?.insightId,
    //       "6724663bf522227ae5132c15",
    //       10
    //     );

    //     console.log({ response });
    //     if (
    //       response.data.top_offers?.length > 0 ||
    //       response.data.top_offers?.length > 0
    //     ) {
    //       let topCategoryList = await getCategoryByIdMultiple({
    //         categoryIds: response?.data?.top_categories,
    //       });
    //       let topOffersList = await getOfferByIdMultiple({
    //         offerIds: response?.data?.top_offers,
    //       });
    //       console.log({ topOffersList });
    //       this.setState({
    //         topOffersList: topOffersList?.data,
    //         topCategoryList: topCategoryList?.data,
    //       });
    //     }
    //   } catch (error) {
    //     console.error(error);
    //   }
    // }
  }, []);

  const settings = {
    variableWidth: true,
    className: "slider variable-width",
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    cssEase: "cubic-bezier(0.33, 1, 0.68, 1)",
    nextArrow: (
      <div className={"rvow__next"}>
        <ButtonIcon
          buttonIcon={<RiArrowRightSLine />}
          type={"round x"}
          size={"lg-xx"}
          option={"rvow__next"}
        />
      </div>
    ),
    prevArrow: (
      <div className={"rvow__prev"}>
        <ButtonIcon
          buttonIcon={<RiArrowLeftSLine />}
          type={"round x"}
          size={"lg-xx"}
          option={"rvow__prev"}
        />
      </div>
    ),
  };

  const getLocationName = (location) => {
    for (let i = 0; i < location?.length; i++) {
      if (location[i].isActive) {
        return location[i].value;
      }
    }
  };

  return (
    <>
      {topOffersList.length > 0 && (
        <>
          <div className={`recent-view-offer-wrp d-flex-flex-column `}>
            <div className={"rvow__title"}>
              <SectionHeader text={"Your recently viewed offers"} size={"md"} />
            </div>
            <div
              className={`rvow__cards-wrp d-flex flex-column ${
                isCollapse ? "more" : ""
              }`}
            >
              <div className="rvow__cw-cards">
                <Slider
                  {...settings}
                  // ref={(slider) => {
                  //   this.sliderRef = slider;
                  // }}
                  className={
                    "rvow__slider d-flex flex-row justify-content-center"
                  }
                >
                  {topOffersList?.map((offer) => (
                    <OfferCardSmall
                      animate={"animate__fadeIn"}
                      size={"sm"}
                      type={"master"}
                      option={"mt-2 ml-2 mr-2 mb-2"}
                      data={{
                        status: offer?.status,
                        header: offer?.header,
                        bannerImageURL: offer?.bannerImageURL,
                        location: offer?.location,
                        merchantData: false,
                        merchant: {
                          logoURL: offer?.merchantData?.logoURL,
                        },

                        bank: {
                          thumbnailURL: offer?.bank?.thumbnailURL,
                          bankName: offer?.bank?.bankName,
                        },
                        views: offer?.views,
                        interest: offer?.interest,
                        offerType: offer?.offerType,
                        offerRate: offer?.offerRate,
                        offerDateType: offer?.offerDateType,
                        offerEnd: offer?.offerEnd,
                      }}
                      onClick={() => {
                        props.history.push(
                          `/credit-card-offers/${offer.offerId}/${offer.bank?.bankName}/${offer.category?.categoryName}`
                        );
                      }}
                    />
                  ))}
                </Slider>
              </div>
              <div className={"rvow__cw-cards-mobile"}>
                {topOffersList?.map((offer) => (
                  <OfferFavoriteCard
                    status={offer?.status}
                    location={getLocationName(offer?.location)}
                    bannerUrl={offer?.bannerImageURL}
                    logoUrl={offer?.merchantData?.logoURL}
                    merchantName={
                      offer?.merchantData?.merchantName.length > 20
                        ? offer?.merchantData?.merchantName
                            ?.substring(0, 20)
                            .trimEnd() + "..."
                        : offer?.merchantData?.merchantName
                    }
                    header={
                      offer?.header?.length > 20
                        ? offer?.header?.substring(0, 20).trimEnd() + "..."
                        : offer?.header
                    }
                    onClick={() => {
                      props.history.push(
                        `/credit-card-offers/${offer.offerId}/${offer.bank?.bankName}/${offer.category?.categoryName}`
                      );
                    }}
                  />
                ))}
              </div>
            </div>
            <div
              className={
                "rvcw__cw-button flex-column justify-content-center align-items-center mt-1"
              }
            >
              <Button
                size={"sm x"}
                type={"no-border"}
                text={isCollapse ? "View less" : `View more`}
                rightIcon={true}
                rightIconType={"more"}
                rightIconsize={"md-x"}
                reactIcon={
                  isCollapse ? GetIcon("chevronUp") : GetIcon("chevronDown")
                }
                // isLoading={isLoadingMoreOffer}
                onClick={async () => {
                  setIsCollapse(!isCollapse);
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
