import React, { Component } from "react";
import "./styles.scss";
import "./mobile.scss";

import {
  BankInfoCard,
  Button,
  ButtonIcon,
  MainHeader,
  OfferCardFooterItem,
  OfferCardSmall,
  OfferDetailCard,
  PreLoader,
  SectionHeader,
} from "../../components";
import { connect } from "react-redux";
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import {
  createNewImpression,
  createNewImpressionFast,
  getOfferByCategoryByBank,
  getOfferByOfferId,
  getOfferInsights,
} from "../../service";
import * as actionTypes from "../../store/actions/types";
import {
  getGeoLocation,
  getInsight,
  getIPAddress,
  getLocationName,
  userDetails,
} from "../../utils";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { Menu } from "antd";
import {
  getFavoriteOfferByUser,
  getLoginUserDetailsById,
} from "../../service/userApi";
import Footer from "../Footer";
import { Helmet } from "react-helmet";
import RestaurantAdCard from "../advertiesment-2";
import { OfferViewModal, ProfileView } from "../modal";

class ViewOffer extends Component {
  constructor(props) {
    super(props);
    //  const user = userDetails();
    // console.log(user);
    let _this = this;
    this.state = {
      isLoading: false,
      isLoadingCategoryOffers: false,
      isLoadingMoreOffer: false,
      dataSource: null,
      bankData: null,
      categoryData: null,
      merchantData: null,
      sessionUser: null,
      isMobileVerification: false,
      userFavoritesList: [],
      bankMerchantList: [],
      topPicksOffers: [],
      totalBankOfferCount: 0,
      bankImpressionCount: 0,
      pageNo: 1,
      totalCountOffers: 1,
      isPageLoading: false,
      settings: {
        variableWidth: true,
        className: "slider variable-width",
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,

        nextArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowRightSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        prevArrow: (
          <div>
            <ButtonIcon
              buttonIcon={<RiArrowLeftSLine />}
              type={"round"}
              size={"md"}
            />
          </div>
        ),
        insightsData: null,
      },
    };
  }
  async fetchOfferData() {
    document.querySelector("body").scrollTo(0, 0);
    // Fix method name
    let param = window.location.pathname;
    param = param.split("/");
    console.log({ param });
    console.log(param);

    this.setState({ isPageLoading: true, isLoadingCategoryOffers: true });

    if (param && param[2]) {
      let offerId = param[2];
      console.log({ offerId });
      let res = await getOfferByOfferId(offerId);
      if (res && res.status) {
        let offerData = res.data[0];
        let bank = offerData.bank;
        let category = offerData.category;
        let merchant = offerData.merchant;
        console.log({ offerData });
        this.setState({
          isPageLoading: false,
          dataSource: offerData,
          bankData: bank,
          categoryData: category,
          merchantData: merchant,
        });

        let resultOffers = await getOfferByCategoryByBank(
          bank?.bankId,
          category?.categoryId,
          1,
          4
        );

        //get more Insights
        let insightsRes = await getOfferInsights(
          offerData?.merchant?.merchantId,
          offerData?.bank?.bankId,
          offerData?.category?.categoryId,
          9
        );

        console.log("insightsRes", insightsRes);
        this.setState({
          isLoadingCategoryOffers: false,
          insightsData: insightsRes?.data,
          topPicksOffers: resultOffers?.data,
          pageNo: 1,
          totalCountOffers: resultOffers?.totalCount,
        });

        await this.creteImpression(
          offerId,
          bank?.bankId,
          category?.categoryId,
          merchant?.merchantId
        );

        // this.props.onClickViewMoreOfferDetails(
        //     offerData,
        //     category,
        //     bank,
        //     merchant
        // );
      }
    } else {
      console.error("Invalid path or parameter not found");
    }
  }

  async getMoreOffers() {
    this.setState({
      isLoadingMoreOffer: true,
    });
    let resultOffers = await getOfferByCategoryByBank(
      this.state.bankData?.bankId,
      this.state.categoryData?.categoryId,
      this.state.pageNo + 1,
      4
    );
    console.log("more_data", resultOffers);
    let offersList = resultOffers?.data;
    if (await resultOffers) {
      this.setState((prevState) => ({
        isLoadingMoreOffer: false,
        topPicksOffers: [...prevState.topPicksOffers, ...offersList],
        pageNo: prevState.pageNo + 1,
        totalCountOffers: resultOffers?.totalCount,
      }));
    }
  }

  async creteImpression(offerId, bankId, merchantId, categoryID) {
    try {
      // Get the geolocation
      const position = await getGeoLocation();
      const { latitude, longitude } = position?.coords;
      // Wait for all promises to resolve
      const [location, ip] = await Promise.all([
        getLocationName(latitude, longitude),
        getIPAddress(),
      ]);
      //let state = userDetails();
      //create impression
      const insight = await getInsight();
      console.log("Insight:", insight);
      let impressionRes = await createNewImpression({
        merchantId: merchantId,
        offerId: offerId,
        categoryId: categoryID,
        bankId: bankId,
        latitude: position?.coords?.latitude,
        longitude: position?.coords?.longitude,
        location: location,
        ip: ip,
        user: this.state.sessionUser?.userId,
        insightId: insight?.insightId,
      });
      console.log({ impressionRes });

      if (impressionRes?.data?.impressionId) {
        let impressionResFast = await createNewImpressionFast({
          impression_id: impressionRes?.data?.impressionId,
          insight_id: insight?.insightId,
          offer_id: offerId,
          merchant_id: merchantId,
          category_id: categoryID,
          bank_id: bankId,
          count: 1,
          ip_address: ip,
          latitude: latitude,
          longitude: longitude,
          formatted_address: location?.formatted_address,
        });
        console.log({ impressionResFast });
      }
      // props.setUserInsight({
      //   data: insightBody
      // });
    } catch (error) {
      console.error("Error:", error);
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (prevProps.location.pathname !== this.props.location.pathname) {
      if (this.props.location.pathname) {
        await this.fetchOfferData();
      }
    }
  }

  checkIsFavorite = (offerId) => {
    const { userFavoritesList } = this.state;
    let isAvailable = false;

    if (userFavoritesList && userFavoritesList.length > 0) {
      for (let i in userFavoritesList) {
        if (userFavoritesList[i]?.offerId === offerId) {
          isAvailable = true;
        }
      }
    }
    return isAvailable;
  };

  async componentDidMount() {
    document.querySelector("body").scrollTo(0, 0);
    console.log("merchant profile loading....");
    this.setState({ isLoading: true });

    let sessionUser = userDetails();
    if (sessionUser?.userId && sessionUser.userType) {
      console.log("sessionUser", sessionUser);
      this.setState({ sessionUser: sessionUser });
      this.props.onAddLoginUser(sessionUser);
      this.props.userLogged();
      let favoriteData = await getFavoriteOfferByUser(sessionUser?.userId);
      if (favoriteData?.status) {
        console.log("favoriteData", favoriteData);
        this.setState({
          isMobileVerification: favoriteData?.data?.isMobileVerification,
          userFavoritesList: favoriteData?.data?.favorites,
        });
      }
    }
    const { location } = this.props;

    await this.fetchOfferData(); // Call the corrected method name
  }

  handleNavigate = () => {
    this.props.history.push("/");
    this.props.setFilterOptions();
  };

  render() {
    const {
      dataSource,
      bankData,
      categoryData,
      merchantData,
      topPicksOffers,
      settings,
      insightsData,
      sessionUser,
      isMobileVerification,
      isFavoriteLoading,
      isLoadingCategoryOffers,
      isLoadingMoreOffer,
      isPageLoading,
    } = this.state;
    const profileMenu = (
      <Menu>
        <Menu.Item>
          <a target="_blank" rel="noopener noreferrer">
            Setting
          </a>
        </Menu.Item>
        <Menu.Item>
          <a
            rel="noopener noreferrer"
            onClick={() => this.props.onClickOpenProfileView()}
          >
            Portal
          </a>
        </Menu.Item>
        <Menu.Item>
          <a onClick={() => this.onClickLogOut()} rel="noopener noreferrer">
            Logout
          </a>
        </Menu.Item>
      </Menu>
    );
    const { offerData, selectedOffer, logInUser, isLoading } = this.props;

    return (
      <>
        {this.props.isOfferView && <OfferViewModal />}
        {/*create new event*/}
        <Helmet>
          <title>
            {`${bankData?.bankName || "Top"} Credit Card Offers | ${
              dataSource?.header || "Exclusive Deals"
            } | ${categoryData?.categoryName || "Sri Lanka"}`}
          </title>
          <meta
            name="description"
            content={`Unlock the best ${
              bankData?.bankName || "credit card"
            } offers and promotions in Sri Lanka. Enjoy ${
              categoryData?.categoryName || "exclusive deals"
            } and discounts from top banks like ${
              bankData?.bankName || "leading banks"
            }. Start saving today with CreditCardOffers.lk.`}
          />
          <link
            rel="canonical"
            href={`https://www.creditcardoffers.lk/${
              dataSource?.offerId || "offers"
            }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }`}
          />
        </Helmet>

        <Helmet>
          <script type="application/ld+json">
            {`
                {
                  "@context": "https://schema.org",
                  "@type": "WebPage",
                  "name": "${
                    bankData?.bankName || "Top Bank"
                  } Credit Card Offers | ${
              categoryData?.categoryName || "Exclusive Deals"
            } in Sri Lanka",
                  "url": "https://www.creditcardoffers.lk/${
                    dataSource?.offerId || "offers"
                  }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }",
                  "description": "Discover ${
                    bankData?.bankName || "leading bank"
                  } credit card offers and exclusive ${
              categoryData?.categoryName || "promotions"
            } in Sri Lanka. Save more on ${
              categoryData?.categoryName || "shopping"
            }, travel, and dining with CreditCardOffers.lk.",
                  "mainEntityOfPage": {
                    "@type": "WebPage",
                    "@id": "https://www.creditcardoffers.lk/${
                      dataSource?.offerId || "offers"
                    }/${bankData?.bankName?.toLowerCase() || "all-banks"}/${
              categoryData?.categoryName?.toLowerCase() || "categories"
            }"
                  }
                }
              `}
          </script>
        </Helmet>

        <ProfileView />
        <MainHeader
          logInUser={
            sessionUser && sessionUser?.userId && sessionUser?.userType
              ? sessionUser
              : null
          }
          history={this.props.history}
          privateUser={require("../../assets/img/default-avatar.jpg")}
          profileMenu={profileMenu}
          onClickOpenNewOffer={() => this.props.onClickOpenNewOffer()}
          isLogin={this.props.isLogin}
          onClickSignUp={() => this.props.onClickOpenSignUp()}
          onClickLogOut={() => this.onClickLogOut()}
          onClickSignIn={() => this.props.onClickOpenSignIn()}
        />
        {/*{!isMobileVerification && (*/}
        {/*  <InfomationBox*/}
        {/*    type={"full-width"}*/}
        {/*    tag={"verification"}*/}
        {/*    title={"Business Verification Review"}*/}
        {/*    message={*/}
        {/*      "Your profile has been submitted & will be reviewed by our team."*/}
        {/*    }*/}
        {/*    icon={<RiHomeGearLine />}*/}
        {/*    buttonIcon={<RiLinksLine />}*/}
        {/*    messageType={"businessVerification"}*/}
        {/*    onClick={async () => {*/}
        {/*      if (logInUser && logInUser.userId) {*/}
        {/*        const token = getToken();*/}

        {/*        let res = await newOTPVerify(*/}
        {/*          logInUser.user.userId,*/}
        {/*          "MOBILE",*/}
        {/*          token,*/}
        {/*          logInUser.user.mobileNo*/}
        {/*        );*/}
        {/*        if (res && res.data && res.data.status) {*/}
        {/*          this.props.history.push("/verify-mobileNo");*/}
        {/*          this.props.openSignupOTP({*/}
        {/*            user: logInUser.user,*/}
        {/*            token: token,*/}
        {/*          });*/}
        {/*        }*/}
        {/*      } else {*/}
        {/*        this.props.history.push("/sign-up");*/}
        {/*      }*/}
        {/*    }}*/}
        {/*  />*/}
        {/*)}*/}
        <div className={"console-inner-wrapper view-offer"} id={"viewOfferId"}>
          <PreLoader isLoading={isPageLoading} />
          <div className={"container"}>
            <div className={"row"}>
              <>
                {/*======== offer details ========*/}
                <div className={"col-12 col-lg-9 col-sm-12 col-md-9 mf-0"}>
                  <div className={"offer-view-page-wrapper d-flex flex-column"}>
                    <div className={"Breadcrumb"}>
                      <div className="home-url" onClick={this.handleNavigate}>
                        Home
                      </div>
                      / View offer /{" "}
                      {bankData?.bankName?.length > 15
                        ? bankData?.bankName.substring(0, 15).trimEnd() + "..."
                        : bankData?.bankName}{" "}
                      / {categoryData?.categoryName}
                    </div>
                    {/*==== OFFER VIEW PAGE====*/}
                    {!isPageLoading && (
                      <>
                        <div className={"coo__banner_wrapper"}>
                          <div className={"offv_bg"}>
                            <img
                              src={dataSource?.bannerImageURL}
                              alt="credit card offers"
                            />
                          </div>

                          <div
                            className={
                              "coo__offer-view-header d-flex align-items-center"
                            }
                          >
                            <div className={"coo__merchant-logo"}>
                              <img
                                src={merchantData?.logoURL}
                                alt="creditcard offers"
                              />
                            </div>
                            <div className={"d-flex flex-column"}>
                              <div className={"coo__ov-title"}>
                                {merchantData?.merchantName}
                              </div>
                              <h1 className={"text-header"}>
                                <>{dataSource?.header}</>
                              </h1>
                            </div>
                          </div>

                          <div className={"coo_offer_banner-wrapper"}>
                            <img
                              src={dataSource?.bannerImageURL}
                              alt="credit card offers"
                            />
                            <div className={"coo__bw__offer-tag"}>
                              {dataSource?.offerType === "off" && (
                                <>{dataSource?.offerRate}% off</>
                              )}
                              {dataSource?.offerType === "upto" && (
                                <>upto {dataSource?.offerRate}% off</>
                              )}
                              {dataSource?.offerType === "customize" && (
                                <>{dataSource?.offerRate}</>
                              )}

                              {/*{dataSource?.offerType === "off" && (*/}
                              {/*  <>{dataSource?.offerRate}% off</>*/}
                              {/*)}*/}
                              {/*{dataSource?.offerType === "upto" && (*/}
                              {/*  <>upto {dataSource?.offerRate}% off</>*/}
                              {/*)}*/}
                              {/*{dataSource?.offerType === "customize" && (*/}
                              {/*  <>{dataSource?.offerType}</>*/}
                              {/*)}*/}
                            </div>
                          </div>

                          {/*<div className={"coo__offer-view-banner-black-layer"} />*/}
                        </div>

                        {/* mobile wrapper start */}
                        <div className={"coo__banner_wrapper-mobile"}>
                          <div className={"coo_offer_banner-wrapper"}>
                            <img
                              src={dataSource?.bannerImageURL}
                              alt="credit card offers"
                            />
                          </div>
                          <div
                            className={
                              "coo__offer-view-header-mobile d-flex flex-column"
                            }
                          >
                            <div className={"coo__merchant-logo-mobile"}>
                              <img
                                src={merchantData?.logoURL}
                                alt="creditcard offers"
                              />
                            </div>
                            {/* <div className={"d-flex flex-column"}> */}
                            <div className={"coo__ov-title-mobile"}>
                              {merchantData?.merchantName}
                            </div>
                            <h1 className={"text-header-mobile"}>
                              <>{dataSource?.header}</>
                            </h1>
                          </div>

                          <div
                            className={"coo__offer-view-banner-black-layer"}
                          />
                        </div>

                        {/* mobile wrapper start */}
                        <div
                          className={
                            "coo__offer-view-content d-flex flex-column"
                          }
                        >
                          <OfferDetailCard
                            type={"tag"}
                            option={"d-flex flex-column text-left mb-2 "}
                            description={null}
                            tagList={dataSource?.location}
                            title={"Offer valid location"}
                            tagType={"yellow"}
                          />

                          <OfferDetailCard
                            type={"tag"}
                            option={"d-flex flex-column text-left mb-2 "}
                            description={null}
                            tagList={dataSource?.cardTypes}
                            title={"Valid card"}
                            tagType={"green"}
                          />
                          <OfferDetailCard
                            type={"offRate"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            offerType={dataSource?.offerType}
                            offerRate={dataSource?.offerRate}
                            title={"Offer"}
                          />

                          <OfferDetailCard
                            type={"text"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            offerDateType={dataSource?.offerDateType}
                            offerStart={dataSource?.offerStart}
                            offerEnd={dataSource?.offerEnd}
                            title={"Validity"}
                          />
                          {offerData &&
                            dataSource?.location &&
                            dataSource?.location.value && (
                              <OfferDetailCard
                                type={"text"}
                                option={
                                  "d-flex flex-column text-left mb-2 mt-3"
                                }
                                description={
                                  offerData &&
                                  dataSource?.location &&
                                  dataSource?.location.value
                                }
                                title={"Address"}
                              />
                            )}
                          {offerData &&
                            dataSource?.merchantData &&
                            dataSource?.merchantData.contactNo && (
                              <OfferDetailCard
                                type={"text"}
                                option={
                                  "d-flex flex-column text-left mb-2 mt-3"
                                }
                                description={
                                  offerData &&
                                  dataSource?.merchantData &&
                                  dataSource?.merchantData.contactNo
                                }
                                title={"Contact"}
                              />
                            )}

                          <OfferDetailCard
                            type={"text"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            description={dataSource?.description}
                            isHtml={true}
                            title={"Offer Description"}
                          />

                          <OfferDetailCard
                            type={"termsConditions"}
                            option={"d-flex flex-column text-left mb-2 mt-3"}
                            termsConditions={dataSource?.termsConditions}
                            title={"Terms & Conditions"}
                          />

                          <div
                            className={
                              "offer-card-footer-wrapper mt-2 mb-2 d-flex"
                            }
                          >
                            {/* <OfferCardFooterItem
                              title={"Category"}
                              content={
                                categoryData && categoryData?.categoryName
                              }
                              type={"view"}
                            />

                            <OfferCardFooterItem
                              title={"Views"}
                              content={dataSource?.views}
                              type={"view"}
                            /> */}
                            <OfferCardFooterItem
                              style={"category"}
                              title={"Category"}
                              content={
                                categoryData && categoryData?.categoryName
                              }
                              iconName={categoryData && categoryData?.iconName}
                              type={"icon"}
                            />

                            <OfferCardFooterItem
                              style={"views"}
                              title={"Views"}
                              content={dataSource?.views}
                              iconName={"eye"}
                              type={"icon"}
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {/* ==== Shimmer start ===== */}
                    {isPageLoading && (
                      <>
                        <div className={"coo__banner_wrapper-shimmer"}>
                          <div
                            className={
                              "coo__offer-view-header-shimmer d-flex align-items-center"
                            }
                          >
                            <div
                              className={"coo__merchant-logo-shimmer shimmerBG"}
                            ></div>
                            <div className={"d-flex flex-column"}>
                              <div
                                className={"coo__ov-title-shimmer shimmerBG"}
                              ></div>
                              <div
                                className={"text-header-shimmer shimmerBG"}
                              ></div>
                            </div>
                          </div>
                          <div
                            className={
                              "coo_offer_banner-wrapper-shimmer shimmerBG"
                            }
                          >
                            <div
                              className={"coo__bw__offer-tag-shimmer shimmerBG"}
                            ></div>
                          </div>
                        </div>
                        <div
                          className={
                            "coo__offer-view-content-shimmer d-flex flex-column"
                          }
                        >
                          <div
                            className={"d-flex flex-column"}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div className={"coo__mcs-tag shimmerBG"}></div>
                          </div>
                          <div
                            className={"d-flex flex-column"}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div
                              className={"coo__mcs-subtitle shimmerBG"}
                            ></div>
                          </div>
                          <div
                            className={"d-flex flex-column"}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div
                              className={"coo__mcs-subtitle shimmerBG"}
                            ></div>
                          </div>
                          <div
                            className={"d-flex flex-column"}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div className={"coo__mcs-dec shimmerBG"}></div>
                          </div>
                          <div
                            className={"d-flex flex-column"}
                            style={{ marginBottom: "30px" }}
                          >
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div
                              className={"d-flex flex-row  align-items-center"}
                              style={{ gap: "8px" }}
                            >
                              <div className={"coo__mcs-icon shimmerBG"}></div>
                              <div className={"coo__mcs-term shimmerBG"}></div>
                            </div>
                          </div>
                          <div className={"d-flex flex-column"}>
                            <div className={"coo__mcs-title shimmerBG"}></div>
                            <div
                              className={"d-flex flex-row  align-items-center"}
                              style={{ gap: "8px" }}
                            >
                              <div className={"coo__mcs-icon shimmerBG"}></div>
                              <div className={"coo__mcs-term shimmerBG"}></div>
                            </div>
                          </div>
                          <div
                            className={
                              "offer-card-footer-wrapper-shimmer mt-3  d-flex flex-row"
                            }
                            style={{ gap: "12px" }}
                          >
                            <div
                              className={"coo__mcs-category shimmerBG"}
                            ></div>
                            <div className={"coo__mcs-bank shimmerBG"}></div>
                            {/* <div
                                  className={"coo__mcs-view shimmerBG"}
                                ></div> */}
                          </div>
                        </div>
                      </>
                    )}
                    {/* ==== Shimmer end ===== */}
                    <div className={"ovpw__bottom"}>
                      <div className={"ovpw__b-header"}>
                        <SectionHeader text={"Top Picks for You"} size={"lg"} />
                      </div>
                      <div className={"ovpw__b-offercard-wrap"}>
                        {(topPicksOffers || []).map((item, index) => (
                          <div className="d-flex">
                            <OfferCardSmall
                              key={item.offerId}
                              id={`${item.offerId}`}
                              animate={"animate__fadeIn"}
                              isFavorites={this.checkIsFavorite(item.offerId)}
                              isLoading={isLoadingCategoryOffers}
                              data={item}
                              size={"lg"}
                              type={"master"}
                              onClick={async () => {
                                console.log("click_offer_view", item);
                                // const cardElement = document.getElementById(
                                //   `offer-card-${item.offerId}`
                                // );
                                // const scrollPosition = cardElement.offsetTop;
                                // sessionStorage.setItem(
                                //   "scrollPosition",
                                //   scrollPosition
                                // );
                                this.props.history.push(
                                  `/credit-card-offers/${item.offerId}/${item.bank?.bankName}/${item.category?.categoryName}`
                                );
                                // this.props.onClickViewMoreOfferDetails(
                                //   item,
                                //   item?.category,
                                //   item?.bank
                                // );

                                //========= createNewImpression =========
                                const position = await getGeoLocation();
                                console.debug("position", position);
                                const { latitude, longitude } =
                                  position?.coords;
                                const insight = await getInsight();
                                const [location, ip] = await Promise.all([
                                  getLocationName(latitude, longitude),
                                  getIPAddress(),
                                ]);
                                let state = userDetails();
                                console.debug("Insight:", insight);
                                let impressionRes = await createNewImpression({
                                  merchantId: item?.merchantId,
                                  offerId: item?.offerId,
                                  categoryId: item?.categoryId,
                                  bankId: item?.bankId,
                                  latitude: position?.coords?.latitude,
                                  longitude: position?.coords?.longitude,
                                  location: location,
                                  ip: ip,
                                  user: state?.userId,
                                  insightId: insight?.insightId,
                                });
                                console.info("impression_res", impressionRes);
                                ///==================================================
                              }}
                            />
                          </div>
                        ))}
                      </div>

                      {this.state.totalCountOffers - topPicksOffers?.length >
                        0 && (
                        <div className={"ovpw__b-action"}>
                          <Button
                            size={"xl"}
                            type={"light"}
                            text={`View more`}
                            isLoading={isLoadingMoreOffer}
                            onClick={async () => {
                              await this.getMoreOffers();
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/*======== bank-details ========*/}
                <div className={"col-12 col-lg-3 col-sm-12 col-md-3 "}>
                  {/* <div className={"bank-details-wrapper"}>
                      <div className={"d-flex flex-row align-items-center"}>
                        <div className={"bdw__logo"}>
                          <img
                            className={"lading-bg"}
                            src={bankData && bankData?.logoURL}
                            alt="all offers in one place"
                          />
                        </div>
                        <div className={"bdw__title"}>
                          {bankData && bankData?.bankName}
                        </div>
                      </div>
                      <div className={"bdw__description mb-4"}>
                        {bankData && bankData?.description}
                      </div>
                      <div
                        className={"d-flex flex-row align-items-center mb-4"}
                      >
                        <div className={"bdw__icon"}>
                          <RiGlobalLine />
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"bdw__subtitle"}>web</div>
                          <div className={"bdw__detail"}>
                            {bankData && bankData?.webURL}
                          </div>
                        </div>
                      </div>
                      <div
                        className={"d-flex flex-row align-items-center mb-4"}
                      >
                        <div className={"bdw__icon"}>
                          <RiPhoneLine />
                        </div>
                        <div className={"d-flex flex-column"}>
                          <div className={"bdw__subtitle"}>contactNo</div>
                          <div className={"bdw__detail"}>
                            {bankData && bankData?.contactNo}
                          </div>
                        </div>
                      </div>

                      {/* <Button
                        size={"sm"}
                       type={"light gray"}
                        reactIcon={<RiArrowLeftLine />}
                        text={"Apply Card"}
                       rightIcon={false}
                       leftIcon={true}
                      leftIconSize={"md"}
                       // onClick={props.onClickSignUp}
                     />
                    </div> */}

                  {/* <div className={"social-media-share-wrapper"}>
                      Share offer
                     <FacebookShareButton
                       url="https://yourwebsite.com"
                       quote="Check out this amazing content!"
                       hashtag="#react"
                      />
                    </div> */}

                  <div className={`sponsor-wrapper`}>
                    <RestaurantAdCard
                      type={"text"}
                      title={"ooge.lk"}
                      description={
                        "Revolutionize your restaurant or hotel management with ooge."
                      }
                      btnName={"Learn More"}
                    />
                  </div>

                  <div className="bank_info_wrapper">
                    <BankInfoCard
                      bankId={bankData?.bankId}
                      logo={bankData?.logoURL}
                      name={bankData?.bankName}
                      categoryName={categoryData?.categoryName}
                      categoryId={categoryData?.categoryId}
                      content={
                        "Explore the Best Discounts Across 10 credit Card Types"
                      }
                      favCount={"1K"}
                      linkContent={`View more ${bankData?.bankName} card offer`}
                      isLoading={isLoading}
                    />
                  </div>
                </div>
              </>
            </div>

            {/*<div className={""}>*/}
            {/*  <div className={"col-12 col-lg-12 col-sm-12 col-md-12 pl-0 pr-0"}>*/}
            {/*    <div className={"coo_feature_wrapper"}>*/}
            {/*      <h1 className={"text-header mb-3"}>*/}
            {/*        <>Insights & More</>*/}
            {/*      </h1>*/}
            {/*      /!*<Slider {...settings}*!/*/}
            {/*      /!*        className={*!/*/}
            {/*      /!*          "rpmw__slider d-flex flex-row justify-content-center"*!/*/}
            {/*      /!*        }>*!/*/}
            {/*      /!*</Slider>*!/*/}
            {/*      <div className={"d-flex flex-wrap"}>*/}
            {/*        {(insightsData || []).map((item, index) => (*/}
            {/*          <FeatureCard*/}
            {/*            dataSource={item}*/}
            {/*            style={"m-2 ml-0 mr-0"}*/}
            {/*            merchantData={item?.merchant}*/}
            {/*            bankData={item?.bank}*/}
            {/*            categoryData={item?.category}*/}
            {/*            onClick={() => {*/}
            {/*              this.props.history.push(*/}
            {/*                `/offer-view/${item.offerId}`*/}
            {/*              );*/}
            {/*            }}*/}
            {/*          />*/}
            {/*        ))}*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>
        <div>
          <Footer />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    offerData: state.form.offerData,
    categoryData: state.form.categoryData,
    bankData: state.form.bankData,
    insightData: state.insightReducer.insightData,
    isSignUp: state.form.signUp,
    isSignIn: state.form.signIn,
    logInUser: state.user.data,
    isLogin: state.user.isLogin,
    isOfferView: state.form.isOfferView,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onClickViewMoreOfferDetails: (payload, categoryData, bankData) =>
      dispatch({
        type: actionTypes.OPEN_OFFER_VIEW_MODAL,
        payload: payload,
        categoryData: categoryData,
        bankData: bankData,
      }),
    openSignupOTP: (payload) =>
      dispatch({ type: actionTypes.OPEN_OTP_VERIFY, payload: payload }),
    onClickLogOut: () => dispatch({ type: actionTypes.LOG_OUT }),
    onClickOpenSignUp: () => dispatch({ type: actionTypes.OPEN_SIGN_UP }),
    onClickOpenSignIn: () => dispatch({ type: actionTypes.OPEN_SIGN_IN }),
    onClickOpenNewOffer: () => dispatch({ type: actionTypes.CREATE_NEW_OFFER }),
    getLoginUserDetailsById: (id) => dispatch(getLoginUserDetailsById(id)),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    userLogged: () => dispatch({ type: actionTypes.USER_LOGGED }),
    onClickOpenProfileView: (payload) =>
      dispatch({ type: actionTypes.OPEN_PROFILE_VIEW, payload: payload }),
    setFilterOptions: () => dispatch({ type: actionTypes.CLEAR_ALL_FILTER }),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOffer)
);

// {dataSource?.label} {"-"}{" "}
// {dataSource?.offerType === "off" && (
//     <>{dataSource?.offerRate}% off</>
// )}
// {dataSource?.offerType === "upto" && (
//     <>upto {dataSource?.offerRate}% off</>
// )}
// {dataSource?.offerType === "customize" && (
//     <>{dataSource?.offerType}</>
// )}
