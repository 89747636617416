import React, { useEffect } from "react";
import "./styles.scss";
import "./mobile.scss";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import { getInsight } from "../../../utils";

const Index = (props) => {
  return (
    <>
      {/* {!props.isLoading && ( */}
      <div
        className={`bsc_main-wrapper d-flex flex-column ${props.type}`}
        onClick={props.onClick}
      >
        <div className={"bsc_bank_logo"}>
          {/* <img src={props?.logoURL} alt={"logo"} className="bcw_logo"/> */}
          <img
            src={props?.bank?.thumbnailURL}
            alt={"logo"}
            className="bcw_logo"
          />
        </div>
        {/* <div className={"bsc_bank_Name"}>{props?.bankName}</div> */}
        <div className={"bsc_bank_Name"}>{props?.bank.bankName}</div>
      </div>
      {/* )}

      {/* Shimmer start */}
      {/* {props.isLoading && (
        <>
          <div className={`bsc_main-wrapper-shimmer d-flex flex-column `}>
            <div className={"bsc_mws-bank_logo shimmerBG"}></div>

            <div className={"bsc_mws-bank_Name shimmerBG"}></div>
          </div>
        </>
      )} */}
    </>
  );
};
export default withRouter(Index);
