import React from "react";
import PropTypes from "prop-types";
import "./styles.scss";
import { BiX } from "react-icons/bi";

const Index = (props) => (
  <div key={props.key} className={`offer-type-tag-wrp ${props.type}`}>
    <div className={"ottw__text"}>{props.text}</div>
  </div>
);

Index.propTypes = {};

Index.defaultProps = {};

export default Index;
