import React, { Component } from "react";
import "./style.scss";
import axios from "axios";
import * as config from "../../../config/baseUrls";
import {
  getMaskedPhoneNumber,
  GetMessageText,
  login,
  StringRegex,
  userLogOut,
  validateNumber,
  validateUserPassword,
} from "../../../utils";

import { Button, Input, ButtonIcon, OtpInput } from "../../../components";

import { RiArrowRightLine, RiCloseLine } from "react-icons/ri";

import { getAllCategory } from "../../../service/categoryApi";

import * as actionTypes from "../../../store/actions/types";
import { openNotification } from "../../../config/notification";
import * as user from "../../../service/userApi";

import { Scrollbars } from "react-custom-scrollbars";
import { connect } from "react-redux";

class Index extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isSuccess: false,
      wizardNo: 1,
      userType: 2,
      tags: [],
      tag: null,
      otp: null,
      formIsValid: false,
      viewPointWidth: 0,
      viewPointHeight: 0,
      displayOtpResend: false,
      dataForm: {
        OTP: {
          key: "OTP",
          elementType: "text",
          elementConfig: {
            type: "input",
            placeholder: "000000",
            minLength: "6",
            maxLength: "6",
          },
          value: "",
          validation: {
            required: true,
            maxLength: 6,
          },
          valid: false,
          touched: false,
          label: "",
        },
        password: {
          key: "password",
          elementType: "input",
          elementConfig: {
            type: "password",
            placeholder: "*************",
          },
          value: "",
          validation: {
            required: true,
          },
          valid: false,
          touched: false,
          label: "Password",
        },
      },
    };

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  updateWindowDimensions() {
    this.setState({
      viewPointWidth: window.innerWidth,
      viewPointHeight: window.innerHeight,
    });
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
    window.addEventListener("scroll", this.handleOnScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleOnScroll);
    window.removeEventListener("resize", this.updateWindowDimensions);
    // window.addEventListener('scroll', this.listenToScroll);
  }

  async sendVerify(userId, OTP, token) {
    this.setState({ isLoading: true, displayOtpResend: false });
    let res = await user.otpVerify(userId, OTP, token);
    if (res && res.data && res.data.status) {
      console.log("sign in user:::", res.data.data);
      this.setState({ isLoading: false, displayOtpResend: false });
      login(res.data.token);
      this.props.onAddLoginUser(res.data.data);
      this.props.onClickCloseSignUp();
      this.props.onClickCloseOTPVerify();
    } else {
      this.setState({ isLoading: false, displayOtpResend: false });
      //todo

      // openNotification({
      //   title: "One time password",
      //   message: "Invalid authentication, Please check again OTP",
      //   type: "danger",
      //   container: "top-center",
      // });
    }
  }

  //==== input Handle Change
  inputHandleChange = (event, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    switch (inputIdentity) {
      case "mobileNo":
        updatedFormElement.value = validateNumber(
          updatedFormElement.value,
          event.target.value.toString()
        );
        break;
      default:
        updatedFormElement.value = event.target.value;
        break;
    }

    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  //=== validation
  checkValidity(value, rules) {
    let isValid = true;

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    if (rules.email) {
      const re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = re.test(String(value).toLowerCase()) && isValid;
    }
    return isValid;
  }

  validation = (validationValue, inputIdentity) => {
    const updateSignUpForm = {
      ...this.state.dataForm,
    };
    const updatedFormElement = {
      ...updateSignUpForm[inputIdentity],
    };

    updatedFormElement.value = validationValue;
    updatedFormElement.valid = this.checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updateSignUpForm[inputIdentity] = updatedFormElement;
    let formIsValid = true;
    for (let inputIdentifier in updateSignUpForm) {
      formIsValid = updateSignUpForm[inputIdentifier].valid && formIsValid;
    }
    this.setState({ dataForm: updateSignUpForm, formIsValid: formIsValid });
  };

  async updateInputValidationErrors() {
    const { wizardNo, userType } = this.state;
    let df = { ...this.state.dataForm };
    let formIsValid = true;
    //========= VALIDATION USER -- OFFER VIEWER
    //this.setState({isLoading: true});
    for (let itemKey of Object.keys(df)) {
      if (itemKey === "mobileNo") {
        if (!df[itemKey].value) {
          df[itemKey].touched = true;
          df[itemKey].valid = false;
          formIsValid = false;
        } else {
          if (df[itemKey].value.length < df[itemKey].validation.maxLength) {
            df[itemKey].touched = true;
            df[itemKey].valid = false;
            df[itemKey].invalidReason = GetMessageText("invalidMobileNo");
            formIsValid = false;
          }
        }
        this.setState({
          dataForm: df,
          formIsValid: formIsValid,
          isLoading: false,
        });
      }
    }
  }

  fetchMobileNumber = (value) => {
    let newValue = value.replace("+", "");
    return (
      "+" + newValue.replace(/\d{8}$/, "") + newValue.replace(/^\d{8}/, "*****")
    );
  };

  async onOtpEnter(otp) {
    console.log("hue otp entered", otp);
    this.setState({ isEnableLoginIn: true, otp: otp });
  }

  async onOtpResend(userId, mobileNo, token) {
    this.setState({ isLoading: true });
    let res = await user.otpSend(userId, mobileNo, token);
    if (res) {
      this.setState({ isLoading: false, displayOtpResend: false });
    }
  }

  onCountdownFinish() {
    this.setState({ displayOtpResend: true, isEnableLoginIn: false });
  }

  render() {
    const { dataForm, isLoading, isSuccess, otp } = this.state;
    const { userData, token, isLogin, isRegister, isOpenVerify } = this.props;
    return (
      <>
        <div
          className={`modal-bg-wrapper  top-form coo__modal-main-wrapper animate__animated ${
            isOpenVerify ? "show-me" : "hide-me"
          } `}
        >
          <div className="row justify-content-md-center">
            <div
              className={
                "col-xs-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 col-xxl-5"
              }
            >
              <div className={"join-form-content-wrapper"}>
                <div className="modal-header-wrapper">
                  <div className={"close-modal-wrapper flex-row"}>
                    <ButtonIcon
                      type={"close"}
                      onClick={() => this.props.onClickCloseOTPVerify()}
                      size={"md"}
                      buttonIcon={<RiCloseLine />}
                    />
                  </div>

                  <div className={"form-inner-wrapper flex-column"}>
                    <div className={"header text-center"}>Verify account</div>
                    <div className={"small-text text-center mb-3"}>
                      {`We have sent a 6 digit code to ${
                        userData && getMaskedPhoneNumber(userData?.mobileNo)
                      } Please enter it below to complete verification`}
                    </div>
                    <OtpInput
                      numberOfInputs={6}
                      timeInterval={15}
                      displayCountdown={!this.state.displayOtpResend}
                      countdownText={"Resend"}
                      onOtpEnter={this.onOtpEnter.bind(this)}
                      onCountdownFinish={this.onCountdownFinish.bind(this)}
                    />

                    <div className={"mt-4"} />
                    {this.state.isEnableLoginIn && (
                      <Button
                        leftIcon={false}
                        rightIcon={true}
                        rightIconType={"dark"}
                        type={"dark"}
                        isLoading={isLoading}
                        onClick={() =>
                          this.sendVerify(userData?.userId, otp, token)
                        }
                        size={"full-width"}
                        reactIcon={<RiArrowRightLine />}
                        text={"Sign in"}
                      />
                    )}

                    {this.state.displayOtpResend && (
                      <div className={"mt-2"}>
                        <Button
                          leftIcon={false}
                          rightIcon={false}
                          isLoading={isLoading}
                          type={"light"}
                          onClick={() =>
                            this.onOtpResend(
                              this.state.userId,
                              dataForm.mobileNo.value,
                              this.state.token
                            )
                          }
                          size={"full-width"}
                          text={"Resend OTP"}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.user.user,
    token: state.user.token,
    isLogin: state.user.isLogin,
    isRegister: state.user.isRegister,
    isOpenVerify: state.user.isOpenVerify,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getLoginUserDetailsById: (id) => dispatch(user.getLoginUserDetailsById(id)),
    onClickOpenSignUp: () =>
      dispatch({ type: actionTypes.OPEN_SIGN_UP, status: true }),
    onClickCloseSignUp: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_UP, status: false }),
    onAddLoginUser: (userDara) =>
      dispatch({ type: actionTypes.ADD_LOGIN_USER, loginUser: userDara }),
    onClickCloseSignIn: () =>
      dispatch({ type: actionTypes.CLOSED_SIGN_IN, status: false }),
    onClickCloseOTPVerify: () =>
      dispatch({ type: actionTypes.CLOSE_OTP_VERIFY }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
