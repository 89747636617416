import React from "react";
import "./styles.scss";
//import "./mobile.scss";
import {
  OfferCardFooterItemSmall,
  OfferInfoCardSmall,
  OfferTypeTag,
  ViewsAndFavoriteCardSmall,
} from "../../../components";
import { RiCalendar2Line } from "react-icons/ri";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const getLocationName = (location) => {
  for (let i = 0; i < location?.length; i++) {
    if (location[i].isActive) {
      return location[i].value;
    }
  }
};

const Index = (props) => {
  const isLogin = useSelector((state) => state.user.isLogin);
  return (
    <>
      {!props.isLoading && (
        <>
          {props.type === "master" && (
            <>
              <div
                className={`offer-view-card-small-wrapper flex-column animate__animated ${props.animate} ${props.size} ${props.option}`}
                onClick={props.onClick}
              >
                {props.data?.status === "expireSoon" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"Ending soon"} type={"ending-soon"} />
                  </div>
                )}
                {props.data?.status === "new" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"New"} type={"new"} />
                  </div>
                )}
                {/*merchant-header*/}
                <>
                  <div
                    className={"ovcsw__merchant-header-small d-flex"}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={"ovcsw__merchant-logo-small-wrp"}>
                      {!props.data.merchantData && props.data.merchant && (
                        <img
                          src={
                            props.data &&
                            props.data.merchant &&
                            props.data.merchant?.logoURL
                          }
                          alt="creditcard offers"
                        />
                      )}
                      {!props.data.merchant && props.data.merchantData && (
                        <img
                          src={
                            props.data &&
                            props.data.merchantData &&
                            props.data.merchantData?.logoURL
                          }
                          alt="credit card offers"
                        />
                      )}
                    </div>
                    <div
                      className={
                        "ovcsw__merchant-header-small-wra d-flex flex-column"
                      }
                    >
                      <div className={"ovcsw__mhs-title"}>
                        {props.data?.merchantData?.merchantName}
                      </div>
                      <div className={"ovcsw__mhs-header"}>
                        {props?.data?.header}
                      </div>
                    </div>
                  </div>
                  <div
                    className={"ovcsw__offer-content-small-wrapper d-flex"}
                    style={{ cursor: "pointer" }}
                    onClick={props.onClick}
                  >
                    <div className={"ovcsw__offer-banner-small"}>
                      <div className={"ovcsw__mobile-location-tag-small"}>
                        {getLocationName(props?.data?.location)}
                      </div>
                      <img
                        className={"offer-banner-small"}
                        loading="lazy"
                        src={props.data.bannerImageURL}
                        alt="credit card offers"
                      />

                      <div className={"ovcsw__offer-tag-small"}>
                        {props.data.offerType === "off" && (
                          <>{props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "upto" && (
                          <>upto {props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "customize" && (
                          <>{props.data?.offerRate}</>
                        )}
                        {props.data.offerType === "discount" && (
                          <>{props.data?.offerRate}% discount</>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/******* offer card footer wrapper ***********/}
                <div className={"ovcsw__offer-card-small-footer-wrapper"}>
                  <div className={"d-flex flex-row"}>
                    <OfferCardFooterItemSmall
                      style={"bank"}
                      title={"Bank"}
                      img={
                        <img
                          className={"lading-bg"}
                          loading="lazy"
                          src={
                            props.data &&
                            props.data?.bank &&
                            props.data?.bank.thumbnailURL
                          }
                          alt="all offers in one place"
                        />
                      }
                      content={
                        props.data?.bank.bankName?.length > 13
                          ? props.data?.bank.bankName
                              ?.substring(0, 13)
                              .trimEnd() + "..."
                          : props.data?.bank.bankName
                      }
                      type={"bank"}
                    />

                    <div className={"ovcsw__views-and-favorite"}>
                      <ViewsAndFavoriteCardSmall
                        views={props.data?.views}
                        favorites={props.data?.interest}
                      />
                    </div>
                  </div>
                  <OfferInfoCardSmall
                    type={"offerValid"}
                    lable={""}
                    title={null}
                    offerStart={props.data.offerStart}
                    offerEnd={props.data.offerEnd}
                    offerDateType={props.data.offerDateType}
                    icon={<RiCalendar2Line />}
                  />
                </div>
              </div>
            </>
          )}
          {props.type === "bank" && (
            <>
              <div
                className={`offer-view-card-small-wrapper flex-column animate__animated ${props.animate} ${props.size}  ${props.option}`}
                onClick={props.onClick}
              >
                {props.data?.status === "expireSoon" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"Ending soon"} type={"ending-soon"} />
                  </div>
                )}
                {props.data?.status === "new" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"New"} type={"new"} />
                  </div>
                )}
                {/*merchant-header*/}
                <>
                  <div
                    className={"ovcsw__merchant-header-small d-flex"}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={"ovcsw__merchant-logo-small-wrp"}>
                      {!props.data.merchantData && props.data.merchant && (
                        <img
                          src={
                            props.data &&
                            props.data.merchant &&
                            props.data.merchant?.logoURL
                          }
                          alt="creditcard offers"
                        />
                      )}
                      {!props.data.merchant && props.data.merchantData && (
                        <img
                          src={
                            props.data &&
                            props.data.merchantData &&
                            props.data.merchantData?.logoURL
                          }
                          alt="creditcard offers"
                        />
                      )}
                    </div>
                    <div
                      className={
                        "ovcsw__merchant-header-small-wra d-flex flex-column"
                      }
                    >
                      <div className={"ovcsw__mhs-title"}>
                        {props?.data?.merchantData?.merchantName}
                      </div>
                      <div className={"ovcsw__mhs-header"}>
                        {props?.data?.header?.length > 20
                          ? props?.data?.header?.substring(0, 20).trimEnd() +
                            "..."
                          : props?.data?.header}
                      </div>
                    </div>
                  </div>
                  <div
                    className={"ovcsw__offer-content-small-wrapper d-flex"}
                    style={{ cursor: "pointer" }}
                    onClick={props.onClick}
                  >
                    <div className={"ovcsw__offer-banner-small"}>
                      <div className={"ovcsw__mobile-location-tag-small"}>
                        {getLocationName(props?.data?.location)}
                      </div>
                      <img
                        className={"offer-banner-small"}
                        loading="lazy"
                        src={props.data.bannerImageURL}
                        alt="credit card offers"
                      />

                      <div className={"ovcsw__offer-tag-small"}>
                        {props.data.offerType === "off" && (
                          <>{props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "upto" && (
                          <>upto {props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "customize" && (
                          <>{props.data?.offerRate}</>
                        )}
                        {props.data.offerType === "discount" && (
                          <>{props.data?.offerRate}% discount</>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/******* offer card footer wrapper ***********/}
                <div className={"ovcsw__offer-card-small-footer-wrapper"}>
                  <OfferCardFooterItemSmall
                    style={"bank"}
                    title={"Bank"}
                    img={
                      <img
                        className={"lading-bg"}
                        loading="lazy"
                        src={
                          props.data &&
                          props.data?.bank &&
                          props.data?.bank.thumbnailURL
                        }
                        alt="all offers in one place"
                      />
                    }
                    content={props.data?.bank.bankName}
                    type={"bank"}
                  />

                  <OfferInfoCardSmall
                    type={"offerValid"}
                    lable={""}
                    title={null}
                    offerStart={props.data.offerStart}
                    offerEnd={props.data.offerEnd}
                    offerDateType={props.data.offerDateType}
                    icon={<RiCalendar2Line />}
                  />
                </div>
              </div>
            </>
          )}
          {props.type === "favourite" && (
            <>
              <div
                className={`offer-view-card-small-wrapper flex-column animate__animated ${props.animate} ${props.size}  ${props.option}`}
                onClick={props.onClick}
              >
                {props.data?.status === "expireSoon" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"Ending soon"} type={"ending-soon"} />
                  </div>
                )}
                {props.data?.status === "new" && (
                  <div className={"ending-soon-tag"}>
                    <OfferTypeTag text={"New"} type={"new"} />
                  </div>
                )}
                {/*merchant-header*/}
                <>
                  <div
                    className={"ovcsw__merchant-header-small d-flex"}
                    style={{ cursor: "pointer" }}
                  >
                    <div className={"ovcsw__merchant-logo-small-wrp"}>
                      {!props.data.merchantData && props.data.merchant && (
                        <img
                          src={
                            props.data &&
                            props.data.merchant &&
                            props.data.merchant?.logoURL
                          }
                          alt="creditcard offers"
                        />
                      )}
                      {!props.data.merchant && props.data.merchantData && (
                        <img
                          src={
                            props.data &&
                            props.data.merchantData &&
                            props.data.merchantData?.logoURL
                          }
                          alt="creditcard offers"
                        />
                      )}
                    </div>
                    <div
                      className={
                        "ovcsw__merchant-header-small-wra d-flex flex-column"
                      }
                    >
                      <div className={"ovcsw__mhs-title"}>
                        {props?.data?.merchantData?.merchantName}
                      </div>
                      <div className={"ovcsw__mhs-header"}>
                        {props?.data?.header?.length > 20
                          ? props?.data?.header?.substring(0, 20).trimEnd() +
                            "..."
                          : props?.data?.header}
                      </div>
                    </div>
                  </div>
                  <div
                    className={"ovcsw__offer-content-small-wrapper d-flex"}
                    style={{ cursor: "pointer" }}
                    onClick={props.onClick}
                  >
                    <div className={"ovcsw__offer-banner-small"}>
                      <div className={"ovcsw__mobile-location-tag-small"}>
                        {getLocationName(props?.data?.location)}
                      </div>
                      <img
                        className={"offer-banner-small"}
                        loading="lazy"
                        src={props.data.bannerImageURL}
                        alt="credit card offers"
                      />

                      <div className={"ovcsw__offer-tag-small"}>
                        {props.data.offerType === "off" && (
                          <>{props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "upto" && (
                          <>upto {props.data?.offerRate}% off</>
                        )}
                        {props.data.offerType === "customize" && (
                          <>{props.data?.offerRate}</>
                        )}
                        {props.data.offerType === "discount" && (
                          <>{props.data?.offerRate}% discount</>
                        )}
                      </div>
                    </div>
                  </div>
                </>

                {/******* offer card footer wrapper ***********/}
                <div className={"ovcsw__offer-card-small-footer-wrapper"}>
                  {/* <ViewsAndFavoriteCardSmall
                    views={props.data?.views}
                    favorites={props.data?.interest}
                  /> */}

                  <OfferInfoCardSmall
                    type={"offerValid"}
                    lable={""}
                    title={null}
                    offerStart={props.data.offerStart}
                    offerEnd={props.data.offerEnd}
                    offerDateType={props.data.offerDateType}
                    icon={<RiCalendar2Line />}
                  />
                </div>
              </div>
            </>
          )}
        </>
      )}
      {/* ==== Shimmer start ===== */}
      {props.isLoading && (
        <>
          <div className={`offercard-small-shimmer d-flex flex-column`}>
            <div className={"d-flex flex-row align-items-center"}>
              <div className={"oss__logo-shimmer shimmerBG"}></div>
              <div className={"d-flex-flex-column"}>
                <div className={"oss__title-shimmer shimmerBG"}></div>
                <div className={"oss__header-shimmer shimmerBG"}></div>
              </div>
            </div>

            <div className={"oss__cs-banner-shimmer shimmerBG"}></div>
            <div
              className={"offercard-small-footer-shimmer d-flex flex-column"}
            >
              <div className={"d-flex flex-row"}>
                <div className={"oss__osfs-bank-shimmer shimmerBG"}></div>
                <div className={"oss__osfs-favorite-shimmer shimmerBG"}></div>
              </div>
              <div className={"oss__osfs-valid-shimmer shimmerBG"}></div>
            </div>
          </div>
        </>
      )}
      {/* ==== Shimmer end ===== */}
    </>
  );
};

Index.propTypes = {};

Index.defaultProps = {};

export default withRouter(Index);
