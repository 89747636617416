import React from "react";
import "./style.scss";
import { FilterColor_1, FilterColor_2, FilterColor_3 } from "../../components";
import { Button } from "../../components";
import ErrorPageFooter from "../ErrorPageFooter";
import { withRouter } from "react-router-dom/cjs/react-router-dom";

const Index = (props) => {
  return (
    <div className="cco_error_page_wrapper d-flex flex-column">
      <div className="epw_artworkTop">
        <img src={require("../../assets/img/join_artWork_top.svg")} />
      </div>
      <div className="container">
        <div className="epw_logo-wrapper">
          <img
            className={"epw_logo sm"}
            src={require("../../assets/img/logo/creditcardoffers.lk.svg")}
          />
        </div>
        <div className="epw_error-image">
          <img src={require("../../assets/img/Group 3186.png")} />
        </div>
        <div className="cco_epw_title">Oops! Page Not Found</div>
        <div className="cco_epw_description">
          We're sorry, but the page may have been moved or no longer exists.
        </div>
        <div className="epw_button ">
          <Button
            text={"Go back to home"}
            type={"dark md"}
            size={"full-width"}
            isLoading={false}
            leftIcon={false}
            rightIcon={false}
            onClick={async () => props.history.push("/")}
          />
        </div>
        <div className="epw_footer">
          <ErrorPageFooter />
        </div>
      </div>
      <div className="cco_epw_filter-bg-1">
        <FilterColor_1 />
      </div>
      <div className="cco_epw_filter-bg-2">
        <FilterColor_2 />
      </div>
    </div>
  );
};
export default withRouter(Index);
