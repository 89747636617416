import React from 'react';

import {ReactNotifications} from 'react-notifications-component'
import "../src/assets/stylesheets/vendor/bootstrap.min.css";
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'

//import {ConnectedRouter} from 'connected-react-router';
import {BrowserRouter as Router} from 'react-router-dom';
//import ConsoleComponent from "./containers/console";
//import PublicRoute from './routes/PublicRoute';
//import PrivateRoute from './routes/PrivateRoute';
import {Helmet} from 'react-helmet'; // Import react-helmet
import {applyMiddleware, createStore} from 'redux';
import {Provider} from 'react-redux'
import thunk from 'redux-thunk';

import AppRouter from './routes/AppRouter';
import rootReducer from './store/reducers/index';


const createHistory = require('history');


//export const history = createHistory();
// API key of the google map

// function logger({getState}) {
//     return next => action => {
//         console.log('will dispatch', action);
//         // Call the next dispatch method in the middleware chain.
//         const returnValue = next(action);
//         console.log('state after dispatch', getState());
//         // This will likely be the action itself, unless
//         // a middleware further in chain changed it.
//         return returnValue
//     }
// }

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

//const store = createStore(rootReducer, ['Use Redux'], applyMiddleware(logger));
const store = createStore(
    rootReducer,
    applyMiddleware(thunk),
);
//inject store to api

function App() {
    return (
        <>
            {/*<div className={'preloader'}/>*/}
            <Helmet>
                <title>Best Credit Card Offers in Sri Lanka | CreditCardOffers.lk</title>
                <meta
                    name="description"
                    content="Find the best credit card offers, deals, and promotions in Sri Lanka. Compare rewards, cashback, discounts, and hotel deals from top banks like Sampath, HNB, and more."
                />
                <meta
                    name="keywords"
                    content="credit card offers Sri Lanka, best credit card deals, Sri Lanka bank promotions, credit card discounts, cashback offers Sri Lanka, hotel discounts, dining promotions, travel rewards, Sampath credit card offers, HNB credit card offers, exclusive card promotions"
                />
                <link rel="canonical" href="https://www.creditcardoffers.lk" />
            </Helmet>

            <Helmet>
                <script type="application/ld+json">
                    {`
                        {
                          "@context": "https://schema.org",
                          "@type": "WebPage",
                          "name": "Best Credit Card Offers in Sri Lanka | CreditCardOffers.lk",
                          "url": "https://www.creditcardoffers.lk",
                          "description": "Find the best credit card offers, deals, and promotions in Sri Lanka. Compare rewards, cashback, discounts, and hotel deals from top banks like Sampath, HNB, and more.",
                          "mainEntityOfPage": {
                            "@type": "WebPage",
                            "@id": "https://www.creditcardoffers.lk"
                          }
                        }
                        `}
                </script>
            </Helmet>
            <ReactNotifications/>
            <Provider store={store}>
                <Router history={createHistory}>
                    <AppRouter/>
                </Router>
            </Provider>

        </>
    );
}

export default  App;
