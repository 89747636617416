import React, { useEffect, useState } from "react";
import "./styles.scss";
import {
  BankFavoriteCard,
  BankName,
  BankOfferLink,
  BankTotalCard,
  FilterColor_3,
  LogoRowCard,
} from "../..";
import { GetIcon } from "../../../config/icon";
import { RiErrorWarningLine, RiHeartLine } from "react-icons/ri";
import { withRouter } from "react-router-dom/cjs/react-router-dom";
import {
  getBankById,
  getBankImpressionCount,
  getOffersByBank,
} from "../../../service";

const Index = (props) => {
  const [merchantList, setMerchantList] = useState([]);
  const [bankData, setBankData] = useState(null);
  const [offerCount, setOfferCount] = useState(0);
  const [impressionCount, setImpressionCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    let resultBank = await getOffersByBank(props.bankId);
    let resultBankImpression = await getBankImpressionCount(props.bankId);
    console.log("getOffersByBank", resultBank);
    console.log("getBankImpressionCount", resultBankImpression);
    setIsLoading(false);
    let bankMerchantList = resultBank?.data?.data;
    let totalBankOfferCount = resultBank?.data?.totalCount;
    let bankImpressionCount = resultBankImpression?.data?.total_impressions;
    setMerchantList(bankMerchantList);
    setOfferCount(totalBankOfferCount);
    setImpressionCount(bankImpressionCount);
  };

  const fetchBankData = async () => {
    if (props.bankId) {
      let resultBank = await getBankById(props.bankId);
      console.log("getBankById", resultBank);
      setBankData(resultBank?.data);
    }
  };

  useEffect(() => {
    if (props.topOffersList) {
      console.log("offersss:::", props.topOffersList);
    }
  }, [props.topOffersList]);

  useEffect(() => {
    if (props.bankId !== "") {
      fetchData();
      fetchBankData();
    }
  }, [props.bankId]);

  return (
    <>
      {!isLoading && (
        <div className="mobile-bank-card-wrapper d-flex flex-column justify-content-between align-items-center">
          <div className={"d-flex flex-row  align-items-center"}>
            <div className={"mbcw__logo"}>
              <img src={bankData?.logoURL} alt={"logo"} />
            </div>
            <div className={"mbcw__name"}>
              {bankData?.bankName.length > 13
                ? bankData?.bankName?.substring(0, 13).trimEnd() + "..."
                : bankData?.bankName}
            </div>
          </div>
          <div
            className={"d-flex flex-row justify-content-between"}
            style={{ width: "60%", marginTop: "12px" }}
          >
            <div
              className={
                "mbcw__total-wrapper d-flex flex-column align-items-center justify-content-center"
              }
            >
              <div className={"mbcw__tw-count"}>{offerCount}</div>
              <div className={"mbcw__tw-title"}>Total Offers</div>
            </div>
            <div
              className={
                "mbcw__impression-wrapper d-flex flex-column align-items-center justify-content-center"
              }
            >
              <div className={"mbcw__iw-count"}>{impressionCount}</div>
              <div className={"mbcw__iw-title"}>Impressions</div>
            </div>
          </div>
        </div>
      )}
      {/* ==== Shimmer start ===== */}
      {isLoading && (
        <>
          <div className="bankInfo_card_shimmer d-flex flex-column justify-content-between align-items-center">
            <div className={"d-flex flex-row  align-items-center"}>
              <div className={"bcs_logo-shimmer shimmerBG"}></div>
              <div className="bcs__bank_name_shimmer">
                <div className={"bcs__bns_title-shimmer shimmerBG"}></div>
              </div>
            </div>
            <div
              className={"d-flex flex-row justify-content-between"}
              style={{ marginTop: "12px", gap: "24px" }}
            >
              <div
                className={
                  "mbcw__total-wrapper d-flex flex-column align-items-center justify-content-center"
                }
              >
                <div className={" bcs__count-shimmer shimmerBG"}></div>
                <div className={"mbcs__tw-title shimmerBG"}></div>
              </div>
              <div
                className={
                  "mbcw__impression-wrapper d-flex flex-column align-items-center justify-content-center"
                }
              >
                <div className={"bcs__total-shimmer shimmerBG"}></div>
                <div className={"mbcs__iw-title shimmerBG"}></div>
              </div>
            </div>
          </div>
          {/* <div className={"bankInfo_card_shimmer d-flex flex-column"}>
            <div className={"d-flex flex-column align-items-center mb-3"}>
              <div className={"bcs_logo-shimmer shimmerBG"}></div>
              <div className={"bcs__bank_name_shimmer d-flex flex row"}>
                <div className={"bcs__bns_title-shimmer shimmerBG"}></div>
                <div
                  className={"bcs__bns_content-shimmer shimmerBG"}
                  style={{ marginBottom: "2px" }}
                ></div>
                <div className={"bcs__bns_content-shimmer shimmerBG"}></div>
              </div>
            </div>
            <div className={"d-flex flex-row justify-content-between mb-3"}>
              <div className={"bcs__favorites-shimmer shimmerBG"}></div>
              <div className={" bcs__count-shimmer shimmerBG"}></div>
            </div>

            <div className={"bcs__total-shimmer shimmerBG mb-4"}></div>

            <div
              className={
                "d-flex flex-row justify-content-center align-items-center mb-3"
              }
            >
              <div className={"bcs__small-logo-shimmer shimmerBG"}></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
              <div
                className={"bcs__small-logo-shimmer shimmerBG"}
                style={{ marginLeft: "-5px" }}
              ></div>
            </div>

            <div className={"bcs__link-shimmer shimmerBG"}></div>
          </div> */}
        </>
      )}
      {/* ==== Shimmer end ===== */}
    </>
  );
};
export default withRouter(Index);
