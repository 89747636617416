import React from "react";
import "./styles.scss";
import "./mobile.scss";

const Index = (props) => (
  <>
    <div className="bank_favorite_wrapper ">
      <div className="bfw_favorite_wrapper">
        <div
          className="bfw_fw_left_icon"
          // style={{ cursor: "pointer" }}
          // onClick={props.onClick}
        >
          {props.icon}
        </div>
        <div className="bfw_fw_content">{props.detail}</div>
      </div>
      {props?.isCount && (
        <div className="bfw_count_wrapper">
          <div className="cw_count">{props.count}</div>
        </div>
      )}
    </div>
  </>
);
Index.defaultProps = {};
export default Index;
