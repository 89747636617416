import axios from "axios";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const IMPRESSION_BASE_URL = process.env.REACT_APP_IMPRESSION_BASE_URL;

export const createNewImpression = async (body) => {
  try {
    // Get response
    let response = await axios.post(`${BASE_URL}/new/impression`, body);
    return response.data;
  } catch (err) {
    console.log("error make new impression record:", err);
    throw err;
  }
};

export const createNewImpressionFast = async (body) => {
  try {
    // Get response
    let response = await axios.post(
      `${IMPRESSION_BASE_URL}/new/impression`,
      body
    );
    return response.data;
  } catch (err) {
    console.log("error make new impression record:", err);
    throw err;
  }
};
