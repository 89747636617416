import React from "react";
import {
  RiAdvertisementLine,
  RiArrowDownSLine,
  RiArrowLeftLine,
  RiArrowRightLine,
  RiBuilding2Line,
  RiCheckLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiComputerFill,
  RiDashboardLine,
  RiEmpathizeLine,
  RiEyeLine,
  RiErrorWarningLine,
  RiFileCopyLine,
  RiFileUploadLine,
  RiGalleryLine,
  RiHeartLine,
  RiLockFill,
  RiPercentLine,
  RiPhoneLine,
  RiSearchEyeFill,
  RiSearchLine,
  RiSmartphoneFill,
  RiShirtLine,
} from "react-icons/ri";
import { IoMdRefresh } from "react-icons/io";
import { BiChair, BiHotel, BiRestaurant, BiShoppingBag } from "react-icons/bi";
import {
  MdFilterList,
  MdOnlinePrediction,
  MdOutlineCardTravel,
  MdOutlineFavoriteBorder,
  MdOutlineHealthAndSafety,
  MdOutlineRamenDining,
  MdWorkspacePremium,
  MdOutlineCastForEducation,
  MdOutlineSolarPower,
  MdPayment,
} from "react-icons/md";
import { PiDressBold } from "react-icons/pi";
import { FiChevronDown, FiChevronUp, FiSliders } from "react-icons/fi";
import { GiHeartNecklace, GiKnifeFork } from "react-icons/gi";
import { BsFillFuelPumpFill } from "react-icons/bs";

export const GetIcon = (_iconType) => {
  switch (_iconType) {
    case "search":
      return <RiSearchLine />;
    case "shopping-cart":
      return <RiSearchEyeFill />;
    case "user":
      return <RiSearchEyeFill />;
    case "leftArrow":
      return <RiArrowLeftLine />;
    case "rightArrow":
      return <RiArrowRightLine />;
    case "dashboard":
      return <RiDashboardLine />;
    case "bank":
      return <RiBuilding2Line />;
    case "offer":
      return <RiPercentLine />;
    case "merchant":
      return <RiEmpathizeLine />;
    case "category":
      return <RiSearchEyeFill />;
    case "upload":
      return <RiFileUploadLine />;
    case "delete":
      return <RiFileUploadLine />;
    case "clothes":
      return <RiShirtLine />;
    case "fuel":
      return <BsFillFuelPumpFill />;
    case "close":
      return <RiCloseLine />;
    case "check":
      return <RiCheckLine />;
    case "eye":
      return <RiEyeLine />;
    case "downArrow":
      return <RiArrowDownSLine />;
    case "refresh":
      return <IoMdRefresh />;
    case "back":
      return <RiArrowLeftLine />;
    case "filter":
      return <MdFilterList />;
    case "search":
      return <RiSearchLine />;
    case "shopping-cart":
      return <RiSearchEyeFill />;
    case "user":
      return <RiSearchEyeFill />;
    case "leftArrow":
      return <RiSearchEyeFill />;
    case "dashboard":
      return <RiDashboardLine />;
    case "bank":
      return <RiBuilding2Line />;
    case "offer":
      return <RiPercentLine />;
    case "merchant":
      return <RiEmpathizeLine />;
    case "category":
      return <RiSearchEyeFill />;
    case "upload":
      return <RiFileUploadLine />;
    case "delete":
      return <RiFileUploadLine />;
    case "privilege":
      return <RiLockFill />;
    case "close":
      return <RiCloseCircleLine />;
    case "media":
      return <RiGalleryLine />;
    case "copy":
      return <RiFileCopyLine />;
    case "advertisment":
      return <RiAdvertisementLine />;
    case "desktop":
      return <RiComputerFill />;
    case "mobile":
      return <RiSmartphoneFill />;
    case "iconClick":
      return <RiCheckLine />;
    case "hotel":
      return <BiHotel />;
    case "supermarket":
      return <BiShoppingBag />;
    case "online":
      return <MdOnlinePrediction />;
    case "electronics&furniture":
      return <BiChair />;
    case "health&insurance":
      return <MdOutlineHealthAndSafety />;
    case "fashion":
      return <PiDressBold />;
    case "restaurant":
      return <BiRestaurant />;
    case "dining":
      return <MdOutlineRamenDining />;
    case "travel":
      return <MdOutlineCardTravel />;
    case "premiumoffers":
      return <MdWorkspacePremium />;
    case "favorite":
      return <MdOutlineFavoriteBorder />;
    case "phone":
      return <RiPhoneLine />;
    case "chevronUp":
      return <FiChevronUp />;
    case "chevronDown":
      return <FiChevronDown />;
    case "slider":
      return <FiSliders />;
    case "education":
      return <MdOutlineCastForEducation />;
    case "solar":
      return <MdOutlineSolarPower />;
    case "Auto":
      return <FiSliders />;
    case "Leisure & Lifestyle":
      return <FiSliders />;
    case "Jewellery":
      return <GiHeartNecklace />;
    case "Pay Plans":
      return <MdPayment />;
    case "heart":
      return <RiHeartLine />;
    case "warning":
      return <RiErrorWarningLine />;
    case "knife":
      return <GiKnifeFork />;
    default:
      return "<icon>";
  }
};
